import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";

import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "../enrollmentListStore/enrollmentMainStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CreateNewFollowUpThread } from "../../../domain/usages/follow-ups/create-new-follow-up-thread";
import { useFollowUpsStore } from "./followUpsStore";
import { RemoteCreateNewFollowUpThread } from "../../../data/usecases/follow-ups/remote-create-new-follow-up-thread";

interface CreateFollowUpSliceType {
  createFollowUpThreadLoading: boolean;
  createFollowUpThread: Function;
}

const initialStates = {
  createFollowUpThreadLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCreateNewFollowUpSlice: StateCreator<
  CreateFollowUpSliceType
> = (set, get) => ({
  ...initialStates,
  createFollowUpThread: async (payload: CreateNewFollowUpThread.Params) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteCreateNewFollowUps: CreateNewFollowUpThread =
      new RemoteCreateNewFollowUpThread(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CREATE_FOLLOW_UP_THREAD}`,
        axiosHttpClient
      );
    set(() => ({ createFollowUpThreadLoading: true }));
    const result = await remoteCreateNewFollowUps.create(payload);

    if (result.success) {
      useFollowUpsStore
        .getState()
        .followUpThreadForEnrolmentId(
          useEnrollmentMainStore.getState().selectedCard.id,
          false
        );
      set(() => ({ createFollowUpThreadLoading: false }));
      Swal.fire("Follow up thread created successfully.", "", "success");
    } else {
      set(() => ({ createFollowUpThreadLoading: false }));
      Swal.fire("Follow up thread creation failed.", "", "error");
    }
  },
});
