import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetDemoRequest } from "../../domain/usages/get-demo-request";
import { GetEnrolmentByConsentToken } from "../../domain/usages/getEnrolmentByConsentToken";

export class RemoteGetEnrolmentByConsentToken implements GetEnrolmentByConsentToken {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(token: string, params?: GetEnrolmentByConsentToken.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":token", token),
      query: params,
      headers: {     
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
    if (httpResponse && httpResponse.status === 200) {
      return httpResponse.data;
    } else if(!httpResponse){
      return 404
    }
  }
}
