import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Typography,
  Slide,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import CancelIcon from "@mui/icons-material/Cancel";

type Props = {
  openCaptureImageModal: boolean;
  handleCaptureFromGallery: Function;
  handleCaptureFromCamera: Function;
  handleCaptureImageModal: Function;
};

const style = {
  position: "absolute",
  bottom: 0,
  width: "100%",
  bgcolor: "background.paper",
  paddingY: 2,
  textAlign: "center",
  borderRadius: "20px 20px 0 0",
};

const CaptureImageModal: React.FC<Props> = ({
  openCaptureImageModal,
  handleCaptureFromCamera,
  handleCaptureFromGallery,
  handleCaptureImageModal,
}) => {
  return (
    <Modal
      open={openCaptureImageModal}
      onClose={() => handleCaptureImageModal()}
    >
      <Slide direction="up" in={openCaptureImageModal} timeout={700}>
        <Box sx={style}>
          <Stack>
            <Button
              onClick={() => handleCaptureFromCamera()}
              startIcon={<CameraAltIcon color={"secondary"} />}
            >
              {" "}
              <Typography textTransform={"none"} color={"secondary"}>
                Capture from Camera
              </Typography>
            </Button>
            <Divider />
            <Button
              onClick={() => handleCaptureFromGallery()}
              startIcon={<CollectionsIcon color={"secondary"} />}
            >
              <Typography textTransform={"none"} color={"secondary"}>
                Select from Gallery
              </Typography>
            </Button>
            <Divider />
            <Button
              onClick={() => handleCaptureImageModal()}
              startIcon={<CancelIcon color="secondary" />}
            >
              <Typography textTransform={"none"} color={"secondary"}>
                Cancel{" "}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Slide>
    </Modal>
  );
};
export default CaptureImageModal;
