import React from "react";
import { GenerateSignedUrlForDocumentUpload } from "../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../domain/usages/confirm-document-upload";
import AstellasEnrollmentForm from "../components/program/AstellasEnrollmentForm";
import OsteoEnrollmentForm from "../components/program/OsteoEnrollmentForm";
import { useEnrollmentMainStore } from "../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { CreateNewEnrollment } from "../../domain/usages/create-new-enrollment";
import FormLoading from "../components/Loading/FormLoading";
import { getDomain } from "../../domain/Utils";
import DomainName from "../../domain/domain";

type Props = {
  generateUploadURL: GenerateSignedUrlForDocumentUpload;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmDocumentUpload;
};

const ProgramEnrolmentPage: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
}) => {
  const { createEnrollmentByPatient } = useEnrollmentMainStore();
  const handleCreateEnrollment = (data: CreateNewEnrollment.Params) => {
    createEnrollmentByPatient(data);
  };

  if (getDomain() == DomainName.ASTELLAS) {
    return (
      <AstellasEnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={"2"}
        successCallback={handleCreateEnrollment}
        title={"Program Enrolment Form"}
      />
    );
  } else if (getDomain() == DomainName.OSTEO) {
    return (
      <OsteoEnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={"1"}
        successCallback={handleCreateEnrollment}
        title={"Program Enrolment Form"}
      />
    );
  } else {
    return <FormLoading />;
  }
};

export default ProgramEnrolmentPage;
