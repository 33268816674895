import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteConfirmDocumentUpload } from "../../../../data/usecases/remote-confirm-document-upload";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { ConfirmDocumentUpload } from "../../../../domain/usages/confirm-document-upload";
import { useDocumentStore } from "./documentStore";
import { usePersonalDetailStore } from "../personalDetailStore.ts/personalDetailsStore";

interface ConfirmUploadSliceType {
  confirmUpload: Function;
  uploadConfirmed: boolean;
}

const initialStates = {
  uploadConfirmed: true,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useConfirmDocumentUploadSlice: StateCreator<
  ConfirmUploadSliceType
> = (set) => ({
  ...initialStates,
  confirmUpload: async (uuid: string) => {
    set(() => ({ uploadConfirmed: true }));
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteConfirmDocumentUpload: ConfirmDocumentUpload =
      new RemoteConfirmDocumentUpload(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CONFIRM_DOCUMENT_UPLOAD}`,
        axiosHttpClient
      );

    let result = await remoteConfirmDocumentUpload.upload({ uuid: uuid });
    if (result) {
      set(() => ({ uploadConfirmed: false }));
      Swal.fire({
        title: "Document Uploaded Successfully",
        timer: 2000,
        icon: "success",
      });
      useEnrollmentMainStore.getState().reloadPage();
    } else {
      Swal.fire({
        title: "Document Upload failed",
        timer: 3000,
        icon: "error",
      });
    }
  },
});
