import {
  Card,
  CardHeader,
  Avatar,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import React from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { usePersonalDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const PatientBasicDetails: React.FC = () => {
  const { selectedPatientPersonalDetails } = usePersonalDetailStore();
  const { sendConsentSMS, selectedCard } = useEnrollmentMainStore();

  const handleSendConsentSMS = () => {
    sendConsentSMS(selectedCard.id);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: " 1px solid #D0CFCF",
          borderRadius: " 12px",
          marginTop: "14px",
          cursor: "pointer",
          paddingRight: "26px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: "#6750A4",
                }}
              >
                {selectedPatientPersonalDetails?.fullName &&
                  selectedPatientPersonalDetails?.fullName[0]}
              </Avatar>
            }
            title={
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}
                width={"100%"}
              >
                <Typography>
                  {selectedPatientPersonalDetails?.fullName}
                </Typography>
                {selectedCard.consent_acknowledgement != 1 && (
                  <Tooltip title={"click to send consent SMS"}>
                    <Box border={"1px solid #3F51B7"} borderRadius={"50%"}>
                      <IconButton
                        color="secondary"
                        onClick={handleSendConsentSMS}
                      >
                        <PendingActionsIcon color="secondary" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            }
            titleTypographyProps={{
              fontFamily: "Segoe UI",
              fontWeight: 500,
              fontSize: "16px",
            }}
            // subheader={selectedPatientPersonalDetails?.mobile}
            subheaderTypographyProps={{
              fontFamily: "Segoe UI",
            }}
          />
        </Stack>
      </Card>
    </>
  );
};
export default PatientBasicDetails;
