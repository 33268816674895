export class Constant {
  static PAGE_LIMIT = 50;
  static FIELD_REQUIRED = "Field Required";
  static NUMBER_ONLY = {
    VALUE:/^\d+$/,
    MESSAGE: "Number Only",
  };
  static CORRECT_PINCODE={
    VALUE: /^[1-9]{1}\d{2}\s?\d{3}$/,
    MESSAGE: "Invalid Pincode"
  }
  static CORRECT_MOBILE = {
    VALUE: /^[6-9]\d{9}$/,
    MESSAGE: "Enter valid 10 digits mobile number",
  };
  static CORRECT_EMAIL= {
    VALUE: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    MESSAGE: "Enter valid email id",
  };
  static YEAR_MONTH_DATE_FORMAT = "YYYY-MM-DD";
  static DATE_MONTH_YEAR_FORMAT = "DD/MM/YYYY";
  static MONTH_DATE_YEAR_FORMAT= "ll";

  static FOLLOW_UP_TITLE_DROP_DOWN = [
    { id: 1, reason: "AE Reporting" },
    { id: 2, reason: "AE Follow-up1" },
    { id: 3, reason: "AE Follow-up2" },
    { id: 4, reason: "Counseling Call" },
    { id: 5, reason: "Diet Call" },
    { id: 6, reason: "Order Placement Call" },
    { id: 7, reason: "TAC Test" },
    { id: 8, reason: "Re-consent Follow-up" },
    { id: 9, reason: "Dose Reminder Call" },
  ];


  static PATIENT_CONSENT = [
    "I confirm I have received information about “OsteoAssist” Program and have had the opportunity to ask questions.",
    "I understand that my decision to enroll into OsteoAssist program is voluntary and requires duly signed enrolment and consent form for availing the services under the program.",
    "I hereby give my consent to GoApptiv Pvt Ltd for contacting me and for collection, storage and processing of my personal information for providing services under the program.",
    "I hereby give my consent to GoApptiv Pvt Ltd for collection, storage and processing and reporting of my personal information only for pharmacovigilance, safety and regulatory requirements and will not disclose the same to any third party whomsoever unless required by law and regulations. I hereby also give my consent to disclose my information for any audit inspection in respect of Administration of program by an independent third-party auditor who will maintain confidentiality of such information.",
    "I hereby give my consent to Cipla and Marketing Authorization holder (MAH) for utilising my information by pharmacovigilance (PV) team(s) of Cipla and MAH or its affiliates for meeting PV, safety and regulatory requirements including PV/audit inspection as maybe required.",
    "I hereby give my consent to GoApptiv Pvt limited, Cipla and MAH for transferring and disclosing any information to other party as may be required for providing the services under the program and I understand that such other party will also be bound by same terms and conditions as are applicable herein to GoApptiv Pvt limited, Cipla and MAH in respect of my personal information.",
  ];
  

  static TERMS_AND_CONDITIONS = [
    "Membership is open to resident of India only and membership is non transferrable.",
    "Acceptance of membership application is at the sole discretion of Cipla.",
    "Patient/member data collected by “OsteoAssist” centre will be kept confidential, will be handled in secure manner, and will be used in line with this patient consent form.",
    "GoApptiv Pvt limited and Cipla will not be liable whatsoever for any loss/damage that may arise out of use of or reliance on any service provided to members under this program.",
    "Members can withdraw the membership anytime by giving a written application to “OsteoAssist” centre.",
    "Patient will always consult his/her treating physician for any treatment advice.",
  ];

  static DISCLAIMER = [
    "Management of this program is by GoApptiv Pvt Ltd where in the patient data and confidentiality norms are stringently followed.",
    "GoApptiv Pvt Ltd has taken due care towards ensuring the correctness of the patient consent, however GoApptiv Pvt. Ltd. holds no liability for any misprint or typo error.",
  ];

  static I_ACCEPT_TEXT =
    `<p style="font-size: 14px; font-family: 'Roboto'; font-weight: 400">I acknowledge that Cipla and MAH reserves the right to vary, amend or terminate the program at any time. The Terms and conditions and my participation in the program shall be governed and construed in accordance with the laws of India. Any claims or disputes arising in relation to the program shall be subject to exclusive jurisdiction of the courts in Mumbai, India. I understand that my personal information will be stored collected and processed on server/database which may be located in India or outside India and such server maybe owned by GoApptiv Pvt limited or Cipla or maybe owned by third party which will provide dedicated space on server/database to GoApptiv Pvt limited for management of the programs information or data and I hereby give my consent to the same. I hereby give my consent to GoApptiv Pvt limited and Cipla for storage, collection or processing of my personal information on such server/database (which may be located in India all outside India) provided that my personal will be kept confidential and will be handled in a secure manner and in line with the terms of patient consent form.</p>`;
}
