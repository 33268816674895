import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteFetchMasters } from "../../../../data/usecases/remote-fetch-masters";
import Endpoints from "../../../../domain/endpoints";
import { Master } from "../../../../domain/models/master";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { FetchMasters } from "../../../../domain/usages/fetch-masters";

interface FetchMasterType {
  fetchProgramDocuments: Function;
  programDocuments: Master[];
}

const initialStates = {
  programDocuments: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchMasterSlice: StateCreator<FetchMasterType> = (set) => ({
  ...initialStates,
  fetchProgramDocuments: async (programId: string) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteFetchMasters: FetchMasters = new RemoteFetchMasters(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_PROGRAM_DOCS}`,
      axiosHttpClient
    );

    let result = await remoteFetchMasters.fetch(programId);
    if (result.success) {
      set(() => ({ programDocuments: result.documents }));
    }
  },
});
