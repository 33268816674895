import StaticProgram from "./StaticProgram";
import EditPrograms from "./EditPrograms";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import OrderPlacingTabs from "./OrderPlacingTabs";

const ProgramDetails: React.FC = () => {
  const { value } = useProgramDetailStore();

  return (
    <>
      {value == 0 && <StaticProgram />}
      {value == 1 && <OrderPlacingTabs />}
      {value == 2 && <EditPrograms />}
    </>
  );
};
export default ProgramDetails;
