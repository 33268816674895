import { HttpConstants } from "../protocols/http/http-constants";
import { FetchProgramEnrollments } from "../../domain/usages/fetch-program-enrollments";
import { HttpGetClient } from "../protocols/http/http-get-client";

export class RemoteFetchProgramEnrollments implements FetchProgramEnrollments {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    page: number,
    params?: FetchProgramEnrollments.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pageNumber", page.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse && httpResponse.status == 200) {
      return httpResponse.data;
    } else if(!httpResponse)
      {
        return 404
      }
    
  }
}
