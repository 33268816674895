import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteFetchProducts } from "../../../../data/usecases/remote-fetch-products";
import Endpoints from "../../../../domain/endpoints";
import { ProductOptionType } from "../../../../domain/models/product-option-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { FetchProducts } from "../../../../domain/usages/fetch-products";

interface FetchProductSliceType {
  productOptions: ProductOptionType[];
  fetchProductOptions: Function;
  productOptionsLoading: boolean;
}

const initialStates = {
  productOptions: [],
  productOptionsLoading: false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchProductSlice: StateCreator<FetchProductSliceType> = (
  set
) => ({
  ...initialStates,
  fetchProductOptions: async (params?:FetchProducts.Params) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteFetchProducts: FetchProducts = new RemoteFetchProducts(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_PRODUCTS}`,
      axiosHttpClient
    );
    set(() => ({ productOptionsLoading: true }));
    let result = await remoteFetchProducts.fetch(params);
    if (result.success) {
      set(() => ({ productOptions: result.products, productOptionsLoading: false }));
    } else {
      set(() => ({ productOptionsLoading: false }));
    }
  },
});
