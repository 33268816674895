import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import React from "react";
import { useState } from "react";
import ProgramDocs from "./program-documents/ProgramDocs";
import ProgramDetails from "./program-details/ProgramDetails";
import PersonalDetails from "./personal-details/PersonalDetails";
import { usePersonalDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import Orders from "./Orders";

const PatientDetailsTabs: React.FC = () => {
  const { handleEdit } = useProgramDetailStore();
  const { handleCloseEditPersonalDetails } = usePersonalDetailStore();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          sx={{
            display: "flex",
            borderRadius: "45px",
            backgroundColor: "#F0F1FB",
            width: "550px",
            justifyContent: "space-between",
            margin: "auto",
          }}
        >
          <Tabs
            sx={{ width: "100%", justifyContent: "space-between" }}
            indicatorColor="secondary"
          >
            <Tab
              onClick={() => handleCloseEditPersonalDetails()}
              label="Personal Details"
              value="1"
              sx={{
                width: "27%",
                textTransform: "unset !important",
                borderRadius: "45px",
                backgroundColor: value == "1" ? "#3F51B7" : "",
                color: value == "1" ? "#FFFFFF" : "#000000",
              }}
            />
            <Tab
              onClick={() => handleEdit(0)}
              label="Program"
              value="2"
              sx={{
                width: "25%",
                textTransform: "unset !important",
                borderRadius: "45px",
                backgroundColor: value == "2" ? "#3F51B7" : "",
                color: value == "2" ? "#FFFFFF" : "#000000",
              }}
            />
            <Tab
              label="Program Docs"
              value="3"
              sx={{
                width: "25%",
                textTransform: "unset !important",
                borderRadius: "45px",
                backgroundColor: value == "3" ? "#3F51B7" : "",
                color: value == "3" ? "#FFFFFF" : "#000000",
              }}
            />
            <Tab
              label="Orders"
              value="4"
              sx={{
                width: "25%",
                textTransform: "unset !important",
                borderRadius: "45px",
                backgroundColor: value == "4" ? "#3F51B7" : "",
                color: value == "4" ? "#FFFFFF" : "#000000",
              }}
            />
          </Tabs>
        </TabList>
        <TabPanel value="1">
          <PersonalDetails />
        </TabPanel>
        <TabPanel value="2">
          <ProgramDetails />
        </TabPanel>
        <TabPanel value="3">
          <ProgramDocs />
        </TabPanel>
        <TabPanel value="4">
          <Orders />
        </TabPanel>
      </TabContext>
    </div>
  );
};
export default PatientDetailsTabs;
