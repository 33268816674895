import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { RemotePackOrder } from "../../../data/usecases/remote-pack-order";
import Endpoints from "../../../domain/endpoints";
import { useOrderDetailsStore } from "./orderDetailsStore";
import Swal from "sweetalert2";
import { PackOrder } from "../../../domain/usages/pack-order";

interface PackOrderSliceType {
  handlePackOrder: Function;
  toBePacked: boolean;
}

const initialStates = {
  toBePacked: true,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const usePackOrderSlice: StateCreator<PackOrderSliceType> = (set) => ({
  ...initialStates,
  handlePackOrder: async () => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remotePackOrder: PackOrder = new RemotePackOrder(
      `${PSP_ORDER_SERVICE}${Endpoints.PACK_ORDER}`,
      axiosHttpClient
    );

    let result = await remotePackOrder.put(
      useOrderDetailsStore.getState().selectedOrder?.id
    );
    if (result?.success) {
      useOrderDetailsStore.getState().reloadPage();
      set(() => ({ toBePacked: false }));
      Swal.fire({ title: result?.message, icon: "success", timer: 2000 });
    } else {
      Swal.fire({ title: result?.errors?.message, icon: "error", timer: 2000 });
    }
  },
});
