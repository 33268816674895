import { useEffect, useState } from "react";
import { GOOGLE_RECAPTCHE_URL } from "../../../base";

declare global {
  interface Window {
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (
        siteKey: string,
        options: { action: string }
      ) => Promise<string>;
    };
  }
}

const useRecaptcha = (siteKey: string, action: string) => {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadRecaptchaScript = (callback: () => void) => {
    const script = document.createElement("script");
    script.src = `${GOOGLE_RECAPTCHE_URL}?render=${siteKey}`;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const executeRecaptcha = () => {
    setToken(null);
    if (!window.grecaptcha) {
      loadRecaptchaScript(() => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(siteKey, { action }).then((token) => {
              setToken(token);
              setIsLoading(false);
            });
          });
        }
      });
    } else {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(siteKey, { action }).then((token) => {
          setToken(token);
          setIsLoading(false);
        });
      });
    }
  };

  // useEffect(() => {
  //   loadRecaptchaScript(() => {
  //     if (window.grecaptcha) {
  //       window.grecaptcha.ready(() => {
  //         window.grecaptcha.execute(siteKey, { action }).then((token) => {
  //           setToken(token);
  //           setIsLoading(false);
  //         });
  //       });
  //     }
  //   });
  // }, [siteKey, action]);

  // useEffect(() => {
  //   const interval = setInterval(executeRecaptcha, 60000);
  //   return () => clearInterval(interval);
  // }, [executeRecaptcha]);

  return { token, executeRecaptcha };
};

export default useRecaptcha;
