import { create } from "zustand";
import { AUTH_HEADER, AUTH_TOKEN_KEY, USER_LOGIN_API } from "../../base";
import Endpoints from "../../domain/endpoints";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { RemoteGetActiveAddress } from "../../data/usecases/remote-get-active-address";
import { ActiveAddress } from "../../domain/models/active-address";

interface ActiveAddressInterface {
  getActiveAddress: Function;
  address: ActiveAddress;
}

const initialStates = {
  address: {} as ActiveAddress,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useActiveAddressStore = create<ActiveAddressInterface>((set) => ({
  ...initialStates,
  getActiveAddress: async () => {
    let token = storage.get(AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteGetActiveAddress = new RemoteGetActiveAddress(
      `${USER_LOGIN_API}${Endpoints.GET_ACTIVE_ADDRESS}`,
      axiosHttpClient
    );

    let result = await remoteGetActiveAddress.get();
    if (result.success) {
      set(() => ({ address: result.address }));
    }
  },
}));
