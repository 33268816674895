import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteUpdatePatientAddresses } from "../../../../data/usecases/remote-update-patient-addresses";
import { UpdatePatientAddresses } from "../../../../domain/usages/update-patient-address";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";

interface UpdateAddressDetailSliceType {
  updatePatientAddresses: Function;
}
const initialStates = {};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdateAddressDetailSlice: StateCreator<
  UpdateAddressDetailSliceType
> = (set, get) => ({
  ...initialStates,
  updatePatientAddresses: async (
    patientId: string,
    contactId: string,
    payload: UpdatePatientAddresses.Params,
    handleCloseDeactivateModal: Function
  ) => {
    let token = storage.get(PATIENT_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `Bearer ${atob(token)}`,
    });

    const remoteUpdatePatientAddresses: UpdatePatientAddresses =
      new RemoteUpdatePatientAddresses(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.UPDATE_PATIENT_ADDRESS_DETAILS}`,
        axiosHttpClient
      );
    handleCloseDeactivateModal();
    usePersonalDetailStore.getState().handleLoading(true);
    let result = await remoteUpdatePatientAddresses.update(
      patientId,
      contactId,
      payload
    );
    if (result._id) {
      usePersonalDetailStore
        .getState()
        .getPatient(
          useEnrollmentMainStore.getState().selectedCard.patient_uuid
        );
      usePersonalDetailStore.getState().handleEditPersonalDetails(null);
      usePersonalDetailStore.getState().handleLoading(false);
      Swal.fire(
        "Patient Address Details has been deactivated successfully.",
        "",
        "success"
      );
    } else {
      usePersonalDetailStore.getState().handleLoading(false);
      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
});
