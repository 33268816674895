import { Typography, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { ProgramItems } from "../../../../../domain/models/program-items-type";
import { Constant } from "../../../../../constants/constant";
import moment from "moment";
import { useSendToCFAStore } from "../../../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import dayjs from "dayjs";
import DomainName from "../../../../../domain/domain";
import { getDomain } from "../../../../../domain/Utils";

const TreatmentCycleArr = [
  {
    name: "Daily",
    value: "daily",
  },
  {
    name: "Weekly",
    value: "weekly",
  },

  {
    name: "3 Weekly",
    value: "3 Weekly",
  },
  {
    name: "Fortnightly",
    value: "fortnightly",
  },
  {
    name: "Every 28 Days",
    value: "every 28 days",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
];

const astellasDiagnosis = [
  {
    id: 1,
    value: "Liver Transplant",
  },
  {
    id: 2,
    value: "Kidney Transplant",
  },
];

const osteoDiagnosis = [
  {
    id: 1,
    value: "Osteoporosis",
  },
];

const EditProgram: React.FC = () => {
  const { selectedCard } = useEnrollmentMainStore();
  const { handleEdit, onUpdate } = useProgramDetailStore();
  const { productOptions, fetchProductOptions } = useSendToCFAStore();

  const { handleSubmit, control, setValue } = useForm<ProgramItems>({
    mode: "onChange",
  });

  const fetchDiagnosis = () => {
    if (getDomain() == DomainName.ASTELLAS) {
      return astellasDiagnosis;
    } else if (getDomain() == DomainName.OSTEO) {
      return osteoDiagnosis;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setValue(
      "transplant_date",
      selectedCard.transplant_date ? dayjs(selectedCard.transplant_date) : null
    );
    setValue("treatment", selectedCard.treatment);

    setValue("diagnosis", selectedCard.diagnosis);
    setValue("dosage", selectedCard.dosage);
    setValue("uom", selectedCard.uom);
    setValue("alt_mobile", selectedCard.alt_mobile);
    setValue(
      "doctor_full_name",
      selectedCard.doctor_full_name ? selectedCard?.doctor_full_name : " "
    );
    setValue("hospital", selectedCard.hospital ? selectedCard?.hospital : "");
    setValue("treatment_cycle", selectedCard.treatment_cycle);
    setValue(
      "prescription_date",
      selectedCard.prescription_date
        ? dayjs(selectedCard.prescription_date)
        : null
    );
    setValue(
      "treatment_start_date",
      selectedCard.treatment_start_date
        ? dayjs(selectedCard.treatment_start_date)
        : null
    );
    setValue(
      "next_free_cycle_date",
      selectedCard.next_free_cycle_date
        ? dayjs(selectedCard.next_free_cycle_date)
        : null
    );
    setValue("no_of_therapy_months", selectedCard.no_of_therapy_months);
    setValue("no_of_free_months", selectedCard.no_of_free_months);
    setValue(
      "last_infusion_date",
      selectedCard.last_infusion_date
        ? dayjs(selectedCard.last_infusion_date)
        : null
    );
    setValue(
      "next_infusion_date",
      selectedCard.next_infusion_date
        ? dayjs(selectedCard.next_infusion_date)
        : null
    );
    setValue("total_cycles_completed", selectedCard.total_cycles_completed);
    setValue("total_cycles_remaining", selectedCard.total_cycles_remaining);
    setValue("comments", selectedCard.comments);
    let params = {
      program_id: selectedCard.program_id,
    };
    fetchProductOptions(params);
  }, [selectedCard.id]);

  const handleClick = (data: ProgramItems) => {
    let payload = {
      product_id: data.product_id ? data.product_id : selectedCard.product.id,
      dosage: parseInt(data.dosage),
      uom: 0,
      hospital: data.hospital,
      alt_mobile: data.alt_mobile,
      doctor_full_name: data.doctor_full_name,
      treatment: " ",
      treatment_cycle: data.treatment_cycle,
      no_of_therapy_months: data.no_of_therapy_months,
      no_of_free_months: data.no_of_free_months,
      diagnosis: data.diagnosis,
      transplant_date:
        data.transplant_date &&
        moment(data.transplant_date.toString()).format("YYYY-MM-DD"),
      prescription_date:
        data.prescription_date &&
        moment(data.prescription_date.toString()).format("YYYY-MM-DD"),
      treatment_start_date:
        data.treatment_start_date &&
        moment(data.treatment_start_date.toString()).format("YYYY-MM-DD"),
      next_free_cycle_date:
        data.next_free_cycle_date &&
        moment(data.next_free_cycle_date.toString()).format("YYYY-MM-DD"),
      last_infusion_date:
        data.last_infusion_date &&
        moment(data.last_infusion_date.toString()).format("YYYY-MM-DD"),
      next_infusion_date:
        data.next_infusion_date &&
        moment(data.next_infusion_date.toString()).format("YYYY-MM-DD"),
      total_cycles_completed: data.total_cycles_completed,
      total_cycles_remaining: data.total_cycles_remaining,
      comments: data.comments,
    };
    onUpdate(payload);
  };

  return (
    <Stack overflow="scroll" height="51vh">
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"space-between"}
        width={"42%"}
        position={"fixed"}
        zIndex={10}
        bgcolor={"white"}
        paddingBottom={2}
      >
        <Stack>
          <Typography>Program Details</Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Button
            sx={{ borderRadius: "45px" }}
            variant="contained"
            onClick={handleSubmit(handleClick)}
          >
            Update
          </Button>
          <Button
            color="secondary"
            variant="text"
            onClick={() => handleEdit(0)}
          >
            CLOSE
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Product
          </Typography>
          <Controller
            name="product_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                size="small"
                select
                defaultValue={selectedCard.product.id}
                fullWidth
                value={value}
                onChange={onChange}
                error={error && true}
                helperText={error?.message}
              >
                {productOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Secondary Contact
          </Typography>
          <Controller
            name="alt_mobile"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              pattern: {
                value: Constant.CORRECT_MOBILE.VALUE,
                message: Constant.CORRECT_MOBILE.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Doctor
          </Typography>
          <Controller
            name="doctor_full_name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color={"text.secondary"}>
            Hospital/Clinic
          </Typography>
          <Controller
            name="hospital"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Diagnosis
          </Typography>
          <Controller
            name="diagnosis"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              >
                {fetchDiagnosis().map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Treatment Cycle
          </Typography>
          <Controller
            name="treatment_cycle"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                defaultValue={selectedCard.treatment_cycle}
                fullWidth
                select
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              >
                {TreatmentCycleArr.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Dosage
          </Typography>
          <Controller
            name="dosage"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Number of Therapy Months
          </Typography>
          <Controller
            name="no_of_therapy_months"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
              pattern: {
                value: Constant.NUMBER_ONLY.VALUE,
                message: Constant.NUMBER_ONLY.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} columnSpacing={3} rowGap={2}>
          <Typography color={"text.secondary"} variant="caption">
            Number of Free Months
          </Typography>
          <Controller
            name="no_of_free_months"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
              pattern: {
                value: Constant.NUMBER_ONLY.VALUE,
                message: Constant.NUMBER_ONLY.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid xs={6} item>
          <Typography color={"text.secondary"} variant="caption">
            Prescription Date
          </Typography>
          <Controller
            name="prescription_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  value={value}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        {getDomain() == DomainName.ASTELLAS && (
          <Grid xs={6} item>
            <Typography color={"text.secondary"} variant="caption">
              Transplant Date
            </Typography>
            <Controller
              name="transplant_date"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="ll"
                    value={value}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    onChange={onChange}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Treatment Start Date
          </Typography>
          <Controller
            name="treatment_start_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  value={value}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Next free cycle Date
          </Typography>
          <Controller
            name="next_free_cycle_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Next Infusion Date
          </Typography>
          <Controller
            name="next_infusion_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  value={value}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Last Infusion Date
          </Typography>
          <Controller
            name="last_infusion_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="ll"
                  value={value}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Total Cycles Completed
          </Typography>
          <Controller
            name="total_cycles_completed"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
              pattern: {
                value: Constant.NUMBER_ONLY.VALUE,
                message: Constant.NUMBER_ONLY.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography color={"text.secondary"} variant="caption">
            Total Cycles Remaining
          </Typography>
          <Controller
            name="total_cycles_remaining"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                value={value}
                size="small"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
              pattern: {
                value: Constant.NUMBER_ONLY.VALUE,
                message: Constant.NUMBER_ONLY.MESSAGE,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color={"text.secondary"} variant="caption">
            Comments
          </Typography>
          <Controller
            name="comments"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                rows={4}
                multiline
                fullWidth
                size="small"
                aria-multiline
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default EditProgram;
