import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CloseFollowUpThread } from "../../../domain/usages/follow-ups/close-follow-up-thread";
import { RemoteCloseFollowUpThread } from "../../../data/usecases/follow-ups/remote-close-follow-up-thread";
import { useFollowUpsStore } from "./followUpsStore";
import { useEnrollmentMainStore } from "../enrollmentListStore/enrollmentMainStore";

interface CloseFollowUpThreadSliceType {
  closeFollowUpThreadLoading: boolean;
  closeFollowUpThread: Function;
}

const initialStates = {
  closeFollowUpThreadLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCloseFollowUpThreadSlice: StateCreator<
  CloseFollowUpThreadSliceType
> = (set, get) => ({
  ...initialStates,
  closeFollowUpThread: async (
    threadId: string,
    handleCloseConfirmModal: Function,
    date: string |null
  ) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteCloseFollowUpThread: CloseFollowUpThread =
      new RemoteCloseFollowUpThread(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CLOSE_FOLLOW_UP_THREAD}`,
        axiosHttpClient
      );
    set(() => ({ closeFollowUpThreadLoading: true }));
    const result = await remoteCloseFollowUpThread.close(threadId);

    if (result.success) {
      handleCloseConfirmModal(null, false);
      if(date){
        useFollowUpsStore
        .getState()
        .followUpThreadForEnrolmentId(
          null,
          false,
          date
        );
       
      } else{
        useFollowUpsStore
        .getState()
        .followUpThreadForEnrolmentId(
          useEnrollmentMainStore.getState().selectedCard.id,
          false
        );
      }
     
      set(() => ({ closeFollowUpThreadLoading: false }));
      Swal.fire("Selected follow thread closed successfully.", "", "success");
    } else {
      handleCloseConfirmModal(null, false);
      set(() => ({ closeFollowUpThreadLoading: false }));
      Swal.fire("Selected follow thread closing failed.", "", "error");
    }
  },
});
