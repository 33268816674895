import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  LOCATION_SERVICE_BASE_URL,
  LOCATION_SERVICE_TOKEN,
} from "../../../../base";
import { RemoteGetAddressBasedOnPincode } from "../../../../data/usecases/remote-get-address-based-on-pincode";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { GetAddressBasedOnPincode } from "../../../../domain/usages/get-address-based-on-pincode";

interface GetAddressBasedOnPincodeSliceType {
  getAddressByPincode: Function;
  getAddressByAltPincode: Function;
  state: string;
  district: string;
  country: string;
  pincode: string;
  city: string;
  secondaryPincode: string;
  secondaryState: string;
  secondaryCountry: string;
  secondaryDistrict: string;
  handlePincode: Function;
  handleAltPincode: Function;
  handleRefreshState: Function;
}
const initialStates = {
  secondaryPincode: "",
  secondaryState: "",
  secondaryCountry: "",
  secondaryDistrict: "",
  pincode: "",
  state: "",
  country: "",
  district: "",
  city: "",
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetAddressBasedOnPincode: StateCreator<
  GetAddressBasedOnPincodeSliceType
> = (set, get) => ({
  ...initialStates,
  getAddressByPincode: async (pincode: string) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `Bearer ${LOCATION_SERVICE_TOKEN}`,
    });

    const remoteGetAddressBasedOnPincode: GetAddressBasedOnPincode =
      new RemoteGetAddressBasedOnPincode(
        `${LOCATION_SERVICE_BASE_URL}${Endpoints.GET_LOCATION}`,
        axiosHttpClient
      );

    let result = await remoteGetAddressBasedOnPincode.fetch(pincode);
    if (result.success) {
      set(() => ({
        state: result.data[0].state,
        district: result.data[0].district,
        country: result.data[0].country,
        city: result.data[0].city,
      }));
    }
  },
  getAddressByAltPincode: async (pincode: string) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: atob(`Bearer ${LOCATION_SERVICE_TOKEN}`),
    });

    const remoteGetAddressBasedOnPincode: GetAddressBasedOnPincode =
      new RemoteGetAddressBasedOnPincode(
        `${LOCATION_SERVICE_BASE_URL}${Endpoints.GET_LOCATION}`,
        axiosHttpClient
      );

    let result = await remoteGetAddressBasedOnPincode.fetch(pincode);
    if (result.success) {
      set(() => ({
        secondaryState: result.data[0].state,
        secondaryDistrict: result.data[0].district,
        secondaryCountry: result.data[0].country,
      }));
    }
  },
  handlePincode: (
    pincode:string
  ) => {
    set(() => ({ pincode: pincode }));
    if (pincode.length === 6) {
      get().getAddressByPincode(pincode);
    }
  },
  handleAltPincode: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(() => ({ secondaryPincode: e.target.value }));
    if (e.target.value.length === 6) {
      get().getAddressByAltPincode(e.target.value);
    }
  },
  handleRefreshState: () => {
    set(() => ({
      secondaryPincode: "",
      secondaryState: "",
      secondaryCountry: "",
      secondaryDistrict: "",
      pincode: "",
      state: "",
      country: "",
      district: "",
      city: "",
    }));
  },
});
