import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteFetchLabReports } from "../../../../data/usecases/remote-fetch-lab-reports";
import { FetchLabReports } from "../../../../domain/usages/fetch-lab-reports";
import { ProgramLabReportOptionType } from "../../../../domain/models/program-lab-report-option-type";

interface FetchLabReportsSliceType {
  labReportOptions: ProgramLabReportOptionType[];
  fetchLabReportOptions: Function;
  labReportOptionsLoading: boolean;
}

const initialStates = {
  labReportOptions: [],
  labReportOptionsLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchLabReportsSlice: StateCreator<FetchLabReportsSliceType> = (
  set
) => ({
  ...initialStates,
  fetchLabReportOptions: async (labUuid: string) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteFetchLabReports: FetchLabReports = new RemoteFetchLabReports(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_LAB_REPORTS}`,
      axiosHttpClient
    );
    let params = {
      lab_uuid: labUuid,
    };
    set(() => ({ labReportOptionsLoading: true }));
    let result = await remoteFetchLabReports.fetch(params);
    if (result.success) {
      set(() => ({
        labReportOptions: result.reports,
        labReportOptionsLoading: false,
      }));
    } else {
      set(() => ({ labReportOptionsLoading: false }));
    }
  },
});
