import moment from "moment";
import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteUpdateProgramDetails } from "../../../../data/usecases/remote-update-program-details";
import Endpoints from "../../../../domain/endpoints";
import { ProgramItems } from "../../../../domain/models/program-items-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { UpdateProgramDetails } from "../../../../domain/usages/update-program-details";
import { UpdateTacTestData } from "../../../../domain/usages/update-tac-test-data";
import { RemoteUpdateTacTestData } from "../../../../data/usecases/remote-update-tact-test-data";

interface UpdateTacTestSliceType {
  handleUpdateTacTestData: Function;
  handleUpdateTacTestDataLoading: boolean;
}
const initialStates = {
  handleUpdateTacTestDataLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdateTacTestSlice: StateCreator<UpdateTacTestSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  handleUpdateTacTestData: async (payload: UpdateTacTestData.Params) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteUpdateTacTestData: UpdateTacTestData =
      new RemoteUpdateTacTestData(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.UPDATE_TAC_DATA}`,
        axiosHttpClient
      );
    set(() => ({ handleUpdateTacTestDataLoading: true }));
    let result = await remoteUpdateTacTestData.update(
      useEnrollmentMainStore.getState().selectedCard.id,
      payload
    );
    if (result.success) {
      set(() => ({ handleUpdateTacTestDataLoading: false }));
        useEnrollmentMainStore.getState().reloadPage();
      Swal.fire("Updated Tac test successfully.", "", "success");
    } else {
      set(() => ({ handleUpdateTacTestDataLoading: false }));
      Swal.fire("Updating Tac test failed.", "", "error");
    }
  },
});
