import { Modal } from "@material-ui/core";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { CreateNewFollowUpThreadType } from "../../../../../../domain/models/create-new-follow-ups-type";
import { useFollowUpsStore } from "../../../../../../store/adminPageStore/followUpStore/followUpsStore";
import { Constant } from "../../../../../../constants/constant";
import { useEnrollmentMainStore } from "../../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import moment from "moment";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import GaTextArea from "../../../../GaTextArea";

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  backgroundColor: "white",
  borderRadius: "22px",
  padding: "20px",
};

type Props = {
  addFollowUpThread: boolean;
  handleCloseModal: Function;
};

const AddNewFollowUps: React.FC<Props> = ({
  addFollowUpThread,
  handleCloseModal,
}) => {
  const { createFollowUpThread, createFollowUpThreadLoading } =
    useFollowUpsStore();
  const { handleSubmit, control, setValue } =
    useForm<CreateNewFollowUpThreadType>({
      mode: "onChange",
    });

  useEffect(() => {
    setValue("title", "");
    setValue("description", "");
    setValue("follow_up_date", dayjs());
  }, [addFollowUpThread]);

  const handleClick = (data: CreateNewFollowUpThreadType) => {
    const payload = {
      enrolment_id: useEnrollmentMainStore.getState().selectedCard.id,
      title: data.title,
      description: data.description,
      follow_up_date:
        data.follow_up_date &&
        moment(data.follow_up_date.toString()).format("YYYY-MM-DD"),
    };
    createFollowUpThread(payload).then(() => handleCloseModal());
  };

  return (
    <Modal open={addFollowUpThread} onClose={() => handleCloseModal()}>
      <Box sx={style}>
        <Grid container>
          <Grid item xs={6}>
            <Typography fontWeight={660} textAlign={"center"}>
              Select Follow up Date
            </Typography>
            <Controller
              name="follow_up_date"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar value={value} onChange={onChange} disablePast />
                </LocalizationProvider>
              )}
              rules={{
                required: { value: true, message: Constant.FIELD_REQUIRED },
              }}
            />
          </Grid>
          <Grid item xs={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={5} paddingLeft={2}>
            <Stack gap={2}>
              <Controller
                name="title"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    onChange={(event: any, newValue) => {
                      onChange(newValue ? newValue.reason : null);
                    }}
                    size="small"
                    options={Constant.FOLLOW_UP_TITLE_DROP_DOWN}
                    getOptionLabel={(option) => `${option.reason}`}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.reason}
                      </li>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={error && true}
                        placeholder="Select Thread Name"
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
              <Controller
                name="description"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <GaTextArea
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={!!error && true}
                    errorMessage={error && error.message ? error.message : ""}
                    placeholder="Enter Description"
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                  validate: {
                    alphanumericLength: (value) => {
                      return (
                        value.length <= 255 ||
                        "Description should not be more than 255 characters "
                      );
                    },
                  },
                }}
              />
              <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                <LoadingButton
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="info"
                  loading={createFollowUpThreadLoading}
                  onClick={() => handleCloseModal(addFollowUpThread)}
                >
                  <Typography textTransform={"none"} color={"black"}>
                    Cancel
                  </Typography>
                </LoadingButton>
                <LoadingButton
                  size="small"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit(handleClick)}
                  loading={createFollowUpThreadLoading}
                >
                  <Typography textTransform={"none"}>Confirm</Typography>
                </LoadingButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default AddNewFollowUps;
