import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteFetchPrograms } from "../../../../data/usecases/remote-fetch-programs";
import Endpoints from "../../../../domain/endpoints";
import { ProgramOptionType } from "../../../../domain/models/program-option-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { FetchPrograms } from "../../../../domain/usages/fetch-programs";

interface FetchProgramSliceType {
  fetchProgramOptions: Function;
  programOptions: ProgramOptionType[];
  programOptionsloading: boolean
}
const initialStates = {
  programOptions: [],
  programOptionsloading: false
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchProgramSlice: StateCreator<FetchProgramSliceType> = (
  set, get
) => ({
  ...initialStates,
  fetchProgramOptions: async (params?: FetchPrograms.Params ) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteFetchPrograms: FetchPrograms = new RemoteFetchPrograms(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_PROGRAMS}`,
      axiosHttpClient
    );
    set(() => ({ programOptionsloading: true }));
    let result = await remoteFetchPrograms.fetch(params);
    if (result.success) {
      set(() => ({ programOptions: result.programs, programOptionsloading: false }));
    }
    else if(result==404){
      get().fetchProgramOptions(params)
    }
  },
});
