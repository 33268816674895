import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { FetchState } from "../../domain/models/fetch-state-type";
import PatientDetails from "../components/patient-information/patient-details/PatientDetails";
import EnrollmentList from "../components/patient-information/EnrollmentList";
import InfiniteScroll from "react-infinite-scroll-component";
import EnrollmentFilters from "../components/filters/EnrollmentFilters";
import DetailsLoading from "../components/Loading/DetailsLoading";
import ListLoading from "../components/Loading/ListLoading";
import { useEnrollmentMainStore } from "../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { usePersonalDetailStore } from "../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import PatientFollowupTabs from "../components/patient-information/follow-up-tab/PatientFollowupTabs";
import Header from "../components/header/Header";
import IMAGES from "../../assets/images";

const SupportAdminPage: React.FC = () => {
  const {
    fetchEnrolmentState,
    enrollmentList,
    fetchEnrollments,
    filter,
    currentPage,
    lastPage,
    totalEnrolmentCount,
  } = useEnrollmentMainStore();
  const { fetchProgramOptions } = useProgramDetailStore();
  const { fetchProductOptions } = useSendToCFAStore();
  const { fetchState } = usePersonalDetailStore();
  useEffect(() => {
    fetchEnrollments(1, filter, false);
    fetchProgramOptions();
    fetchProductOptions();
  }, []);
  const hasMoreData = () => {
    return fetchEnrolmentState == FetchState.SUCCESS
      ? currentPage < lastPage
      : false;
  };

  return (
    <Grid container position="fixed">
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={3} borderRight={"1px solid rgba(221, 221, 221, 0.5)"}>
        <Stack sx={{ backgroundColor: "white" }} gap={2}>
          <EnrollmentFilters />
          {fetchEnrolmentState === FetchState.SUCCESS && (
            <Typography
              variant="subtitle2"
              fontWeight={660}
              marginLeft={1}
              marginBottom={1}
            >
              Total Enrolment Count: {totalEnrolmentCount}
            </Typography>
          )}
        </Stack>
        <Box
          id="div"
          height="80vh"
          position={"fixed"}
          width="25%"
          overflow="auto"
        >
          {fetchEnrolmentState === FetchState.LOADING && <ListLoading />}

          {fetchEnrolmentState === FetchState.ERROR &&
            enrollmentList.length === 0 && (
              <Stack alignItems={"center"} justifyContent={"center"}>
                <img src={IMAGES.EmptyRecords} />
                <Typography
                  fontWeight={660}
                  variant="body1"
                  textAlign={"center"}
                >
                  We couldn't find any records matching your search criteria.
                </Typography>
              </Stack>
            )}

          {fetchEnrolmentState === FetchState.SUCCESS &&
            enrollmentList.length > 0 && (
              <InfiniteScroll
                scrollableTarget="div"
                dataLength={enrollmentList?.length}
                next={() => {
                  fetchEnrollments(currentPage + 1, filter, true);
                }}
                hasMore={hasMoreData()}
                loader={<b>Loading...</b>}
                endMessage={
                  <Typography display={"flex"} justifyContent="center">
                    <b>End...</b>
                  </Typography>
                }
              >
                <EnrollmentList />
              </InfiniteScroll>
            )}
        </Box>
      </Grid>
      <Grid item xs={5.8} borderRight="1px solid rgba(221, 221, 221, 0.5)">
        {fetchState === FetchState.LOADING && <DetailsLoading />}
        {fetchState === FetchState.SUCCESS && <PatientDetails />}
      </Grid>
      <Grid item xs={3}>
        <PatientFollowupTabs />
      </Grid>
    </Grid>
  );
};
export default SupportAdminPage;
