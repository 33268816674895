import React, { useEffect, useState } from "react";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";

type Props = {
  uuid: string;
};

const ImageComponent: React.FC<Props> = ({ uuid }) => {
  const { getProgramDocs, url } = useDocumentStore();

  useEffect(() => {
    if (uuid) {
      getProgramDocs(uuid);
    }
  }, [uuid]);
  const findUrl = (imageUuid: string) => {
    const imageURL = url.find((item) => item.uuid == imageUuid);
    return imageURL?.url;
  };
  return <img src={findUrl(uuid)} alt="" height="300px" width="280px" />;
};

export default ImageComponent;
