import { Grid } from "@mui/material";
import React from "react";
import PatientDetailsTabs from "./PatientDetailsTabs";
import PatientBasicDetails from "../patient-header/PatientBasicDetails";

const PatientDetails: React.FC = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "26px",
        }}
      >
        <PatientBasicDetails />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "26px",
        }}
      >
        <PatientDetailsTabs />
      </Grid>
    </Grid>
  );
};
export default PatientDetails;
