import {
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { UploadFileType } from "../../../../../domain/models/upload-file-type";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { DocumentGroupType } from "../../../../../domain/models/master";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxHeight: "90%",
  bgcolor: "white",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const AddDocumentModal: React.FC = () => {
  const {
    programDocuments,
    toBeUploaded,
    generateSignedUrl,
    handleCloseDocumentModal,
    showAddNewDocumentModal,
    uploadConfirmed,
  } = useDocumentStore();
  const { selectedCard } = useEnrollmentMainStore();
  const [documentGroup, setDocumentGroup] = useState("");
  const [documentType, setDocumentType] = useState<DocumentGroupType[]>([]);
  const [document, setDocument] = useState("");
  const [imageFile, setImageFile] = useState<UploadFileType>(
    {} as UploadFileType
  );
  const [preview, setPreview] = useState("");

  const handleSelectDocumentGroup = (e: any) => {
    setDocumentGroup(e.target.value);
    if (programDocuments.length > 0) {
      for (let i = 0; i < programDocuments.length; i++) {
        if (programDocuments[i].code == e.target.value)
          setDocumentType(programDocuments[i].document_types);
      }
    } else {
      setDocumentType([]);
    }
  };

  const handleSelectDocument = (e: any) => {
    setDocument(e.target.value);
  };

  const handleUploadDocument = (e: any) => {
    setImageFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const handleSubmit = () => {
    generateSignedUrl(imageFile, document, documentGroup);
  };

  useEffect(() => {
    setDocumentGroup("");
    setDocumentType([]);
    setPreview("");
    setDocument("");
  }, [showAddNewDocumentModal]);

  return (
    <>
      <Modal
        open={showAddNewDocumentModal}
        onClose={() => handleCloseDocumentModal()}
      >
        <Box sx={style}>
          <Stack direction="column" spacing={2}>
            <TextField
              select
              fullWidth
              value={documentGroup}
              onChange={handleSelectDocumentGroup}
              label="Select Document Group"
            >
              {programDocuments.length > 0 &&
                programDocuments.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            {documentType.length > 0 && (
              <TextField
                select
                fullWidth
                value={document}
                onChange={handleSelectDocument}
                label="Select Document Type"
              >
                {documentType.map((option) => (
                  <MenuItem key={option.id} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {document && (
              <Button variant="contained" component="label">
                Upload Document
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleUploadDocument}
                />
              </Button>
            )}
            <Box>
              {preview && imageFile && (
                <>
                  <Box textAlign={"center"}>
                    <img
                      width={"250px"}
                      height={"250px"}
                      src={preview}
                      style={{ border: "1px solid", padding: 4 }}
                      alt=""
                    />
                  </Box>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    marginTop={3}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleCloseDocumentModal()}
                    >
                      Close
                    </Button>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Modal open={toBeUploaded && uploadConfirmed}>
        <Box sx={style}>
          <Stack alignItems={"center"} gap={5}>
            <Typography variant="h5">Please Wait!</Typography>
            <Stack direction={"row"} gap={4} alignItems={"center"}>
              <CircularProgress color="success" />
              <Typography>Preparing to upload....</Typography>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddDocumentModal;
