import { AUTH_TOKEN_KEY, AUTH_USER_KEY, PATIENT_TOKEN_KEY, PSP_AUTH_TOKEN_KEY } from "../../base";
import { User } from "../../domain/models/user";
import { LoggedInUser } from "../../domain/usages/logged-in-user";
import { JsonStorage } from "../protocols/storage/json-storage";

export class LocalLoggedInUser implements LoggedInUser {
  userKey: string = AUTH_USER_KEY;
  tokenKey: string = AUTH_TOKEN_KEY;
  pspTokenKey: string = PSP_AUTH_TOKEN_KEY;
  patientTokenKey: string= PATIENT_TOKEN_KEY;

  constructor(private readonly jsonStorage: JsonStorage) {}

  setUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  getUser(): User | null {
    let user = this.jsonStorage.get(this.userKey);
    if (user && user !== "") return JSON.parse(this.decrypt(user));
    return null;
  }

  getToken(): string | null {
    let token = this.jsonStorage.get(this.tokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  getPatientToken(): string | null {
    let token = this.jsonStorage.get(this.patientTokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  getPSPToken(): string | null {
    let token = this.jsonStorage.get(this.pspTokenKey);
    if (token && token !== "") return this.decrypt(token);
    return null;
  }

  setToken(token: string): void {
    this.jsonStorage.add(this.tokenKey, this.encrypt(token));
  }

  setPSPToken(token: string): void {
    this.jsonStorage.add(this.pspTokenKey, this.encrypt(token));
  }

  setPatientToken(token: string): void {
    this.jsonStorage.add(this.patientTokenKey, this.encrypt(token));
  }

  logout(): void {
    this.jsonStorage.remove(this.userKey);
    this.jsonStorage.remove(this.tokenKey);
  }

  resetUser(user: User): void {
    this.jsonStorage.add(this.userKey, this.encrypt(JSON.stringify(user)));
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(value: string) {
    return atob(value);
  }
}
