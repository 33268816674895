import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { useFollowUpsStore } from "../../../../../../store/adminPageStore/followUpStore/followUpsStore";
import moment from "moment";
import { toTitleCase } from "../../../../../../domain/Utils";
import { FollowupThreadType } from "../../../../../../domain/models/followup-thread-type";
import { FollowUps } from "../../../../../../domain/models/follow-ups";
import { Controller, useForm } from "react-hook-form";
import { Constant } from "../../../../../../constants/constant";
import { Dayjs } from "dayjs";
import { LoadingButton } from "@mui/lab";
import { FetchState } from "../../../../../../domain/models/fetch-state-type";
import ListLoading from "../../../../Loading/ListLoading";
import GaTextArea from "../../../../GaTextArea";

type Props = {
  addFollowup: boolean;
  handleOpenAddFollowup: Function;
};

type NewFollowupType = {
  title: string;
  description: string;
  followUpDate: Dayjs | null;
};

const ViewFollowupHistory: React.FC<Props> = ({
  addFollowup,
  handleOpenAddFollowup,
}) => {
  const {
    followUpsForThread,
    fetchFollowUpsForThreadState,
    addCommentsForFollowUp,
    addCommentsForFollowUpLoading,
    addFollowUpForThreadLoading,
    addFollowUpForThread,
    followUpThreads,
  } = useFollowUpsStore();

  const [comment, setComment] = useState<string>("");

  const { handleSubmit, control, setValue } = useForm<NewFollowupType>({
    mode: "onChange",
  });

  const getThreadDetails = (id: number) => {
    const threadDetails = followUpThreads.find((thread) => thread.id == id);
    return threadDetails ? threadDetails : ({} as FollowupThreadType);
  };

  const handleAddFollowup = (data: NewFollowupType) => {
    let payload = {
      title: data.title,
      description: data.description,
      follow_up_date:
        data.followUpDate &&
        moment(data.followUpDate.toString()).format("YYYY-MM-DD"),
    };
    addFollowUpForThread(followUpsForThread[0].thread_id, payload).then(() =>
      handleOpenAddFollowup()
    );
  };

  const handleAddComment = (followup: FollowUps) => {
    let payload = {
      comment: comment,
    };
    addCommentsForFollowUp(followup.thread_id, followup.id, payload);
  };

  useEffect(() => {
    if (addFollowup) {
      setValue("description", "");
      setValue("followUpDate", null);
      setValue("title", "");
    }
  }, [addFollowup]);
  return (
    <>
      {fetchFollowUpsForThreadState === FetchState.LOADING && <ListLoading />}
      {fetchFollowUpsForThreadState === FetchState.SUCCESS &&
        followUpsForThread.length > 0 && (
          <Stack>
            {addFollowup && (
              <Stack gap={2} marginBottom={2}>
                <Controller
                  name="followUpDate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="ll"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        disablePast
                        onChange={onChange}
                      />
                      {!!error && (
                        <Typography variant="caption" color={"error.main"}>
                          {error?.message}
                        </Typography>
                      )}
                    </LocalizationProvider>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: Constant.FIELD_REQUIRED,
                    },
                  }}
                />
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      onChange={(event: any, newValue) => {
                        onChange(newValue ? newValue.reason : null);
                      }}
                      size="small"
                      options={Constant.FOLLOW_UP_TITLE_DROP_DOWN}
                      getOptionLabel={(option) => `${option.reason}`}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option.reason}
                        </li>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={error && true}
                          placeholder="Select follow-up title"
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: Constant.FIELD_REQUIRED,
                    },
                  }}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <GaTextArea
                      value={value}
                      onChange={onChange}
                      error={!!error && true}
                      errorMessage={error && error.message ? error.message : ""}
                      placeholder="Enter Description"
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: Constant.FIELD_REQUIRED,
                    },
                    validate: {
                      alphanumericLength: (value) => {
                        return (
                          value.length <= 255 ||
                          "Description should not be more than 255 characters "
                        );
                      },
                    },
                  }}
                />
                <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                  <Button
                    size="small"
                    fullWidth
                    variant="outlined"
                    color="info"
                    onClick={() => handleOpenAddFollowup(false)}
                  >
                    <Typography textTransform={"none"} color={"black"}>
                      Cancel
                    </Typography>
                  </Button>
                  <LoadingButton
                    loading={addFollowUpForThreadLoading}
                    size="small"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit(handleAddFollowup)}
                  >
                    <Typography textTransform={"none"}>Confirm</Typography>
                  </LoadingButton>
                </Stack>
              </Stack>
            )}

            {followUpsForThread.map((followup, index) => {
              return (
                <Grid container marginBottom={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2">
                      {moment(followup.follow_up_date).format("ll")}
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5}>
                    <Divider orientation="vertical" />
                  </Grid>

                  <Grid item xs={9.5} paddingLeft={1.5}>
                    <Typography variant="subtitle2" fontWeight={660}>
                      {toTitleCase(followup.title)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      marginY={1}
                      color={"info.main"}
                    >
                      {followup.description}
                    </Typography>
                    {followup.status == "pending" &&
                      getThreadDetails(followup.thread_id).status ==
                        "active" && (
                        <Stack gap={1}>
                          <TextField
                            fullWidth
                            placeholder="Add new comment"
                            size="small"
                            onChange={(e) => setComment(e.target.value)}
                          />
                          <LoadingButton
                            loading={addCommentsForFollowUpLoading}
                            variant="contained"
                            color="secondary"
                            sx={{ width: "50%" }}
                            onClick={() => handleAddComment(followup)}
                          >
                            <Typography
                              textTransform={"none"}
                              variant="caption"
                              fontWeight={660}
                            >
                              Comment
                            </Typography>
                          </LoadingButton>
                        </Stack>
                      )}

                    {followup.comments.length > 0 &&
                      followup.comments.map((comment) => {
                        return (
                          <Stack marginTop={2}>
                            <Stack>
                              <Typography variant="subtitle2" fontWeight={660}>
                                {comment.user_full_name}
                              </Typography>
                              <Typography variant="caption" color={"info.main"}>
                                {moment(comment.created_at).format("LLL")}
                              </Typography>
                            </Stack>

                            <Typography variant="subtitle2" color={"info.main"}>
                              {comment.comments}
                            </Typography>
                          </Stack>
                        );
                      })}
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        )}
    </>
  );
};

export default ViewFollowupHistory;
