import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import EditableTypes from "../../../../../domain/enum";
import moment from "moment";

type Props = {};

const BasicDetailsCard: React.FC<Props> = ({}) => {
  const { selectedPatientPersonalDetails, handleEditPersonalDetails } =
    usePersonalDetailStore();
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle2" display="block" fontWeight={660}>
            Basic Details
          </Typography>
          <EditIcon
            color="secondary"
            sx={{ fontSize: "15px" }}
            onClick={() => handleEditPersonalDetails(EditableTypes.BASIC)}
          />
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableRow sx={{ border: 0 }}>
              <TableCell sx={{ border: 0, width: "50%" }}>Full Name:</TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.fullName}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "50%" }}>Gender:</TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.gender ? "XXXXXXXX" : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "50%" }}>
                Date of Birth:
              </TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.dateOfBirth
                  ? moment(selectedPatientPersonalDetails.dateOfBirth).format(
                      "LL"
                    )
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "50%" }}>Age:</TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.age
                  ? selectedPatientPersonalDetails.age
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "50%" }}>
                No of Family Members:
              </TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.noOfFamilyMembers}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "50%" }}>
                Earning Family Members:
              </TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.noOfEarningFamilyMembers}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0, width: "30%" }}>
                Annual Income Of Family:
              </TableCell>
              <TableCell sx={{ border: 0 }}>
                {selectedPatientPersonalDetails.annualIncomeOfFamily}{" "}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default BasicDetailsCard;
