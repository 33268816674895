import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import { RemoteGetPatientDetails } from "../../../../data/usecases/remote-get-patient-details";
import Endpoints from "../../../../domain/endpoints";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import { PersonalDetailsType } from "../../../../domain/models/personal-details-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { GetPatientDetails } from "../../../../domain/usages/get-patient-details";
import Swal from "sweetalert2";

interface GetPatientSliceType {
  getPatient: Function;
  fetchState: FetchState;
  selectedPatientPersonalDetails: PersonalDetailsType;
}

const initialStates = {
  selectedPatientPersonalDetails: {} as PersonalDetailsType,
  fetchState: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetPatientSlice: StateCreator<GetPatientSliceType> = (set) => ({
  ...initialStates,

  getPatient: async (id: string) => {
    let token = storage.get(PATIENT_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `Bearer ${atob(token)}`,
    });

    const remoteGetPatientDetails: GetPatientDetails =
      new RemoteGetPatientDetails(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.GET_PATIENT_DETAILS}`,
        axiosHttpClient
      );

    set(() => ({ fetchState: FetchState.LOADING }));
    let result = await remoteGetPatientDetails.get({ id });
    if (result.success) {
      set(() => ({
        selectedPatientPersonalDetails: result.data,
        fetchState: FetchState.SUCCESS,
      }));
    } else {
      set(() => ({
        fetchState: FetchState.SUCCESS,
      }));
      Swal.fire(result.message, "", "error");
    }
  },
});
