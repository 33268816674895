import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { RemoteAcceptOrder } from "../../../data/usecases/remote-accept-order";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "./orderDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AcceptOrder } from "../../../domain/usages/accept-order";

interface AcceptOrderSliceType {
  handleAcceptOrder: Function;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useAcceptOrderSlice: StateCreator<AcceptOrderSliceType> = (
  set
) => ({
  handleAcceptOrder: async () => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteAcceptOrder: AcceptOrder = new RemoteAcceptOrder(
      `${PSP_ORDER_SERVICE}${Endpoints.ACCEPT_ORDER}`,
      axiosHttpClient
    );

    let orderDetail = useOrderDetailsStore.getState();
    let result = await remoteAcceptOrder.put(orderDetail.selectedOrder?.id);
    if (result?.success) {
      orderDetail.reloadPage();
      Swal.fire({ title: result?.message, icon: "success", timer: 2000 });
    } else {
      Swal.fire({ title: result?.errors?.message, icon: "error", timer: 3000 });
    }
  },
});
