import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";

import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchFollowUpsForThread } from "../../../domain/usages/follow-ups/fetch-follow-ups-for-thread";
import { RemoteFetchFollowUpsForThread } from "../../../data/usecases/follow-ups/remote-fetch-follow-ups-for-thread";
import { FollowUps } from "../../../domain/models/follow-ups";

interface FetchFollowUpForThreadType {
  fetchFollowUpForThread: Function;
  followUpsForThread: FollowUps[];
  fetchFollowUpsForThreadState: FetchState;
}

const initialStates = {
  followUpsForThread: [],
  fetchFollowUpsForThreadState: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchFollowUpForThreadSlice: StateCreator<
  FetchFollowUpForThreadType
> = (set) => ({
  ...initialStates,
  fetchFollowUpForThread: async (id: number, scrolled?: boolean) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteFetchFollowUpsForThread: FetchFollowUpsForThread =
      new RemoteFetchFollowUpsForThread(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_FOLLOW_UPS_FOR_THREAD}`,
        axiosHttpClient
      );

    if (!scrolled) {
      set(() => ({ fetchFollowUpsForThreadState: FetchState.LOADING }));
    }
    let result = await remoteFetchFollowUpsForThread.fetch(id.toString());
    try {
      if (result.success) {
        set(() => ({
          followUpsForThread: result.follow_ups,
          fetchFollowUpsForThreadState: FetchState.SUCCESS,
        }));
      }
    } catch (error) {
      set(() => ({ fetchFollowUpsForThreadState: FetchState.ERROR }));
    }
  },
});
