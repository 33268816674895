import moment from "moment";
import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../../base";
import { RemoteUpdateProgramDetails } from "../../../../data/usecases/remote-update-program-details";
import Endpoints from "../../../../domain/endpoints";
import { ProgramItems } from "../../../../domain/models/program-items-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { UpdateProgramDetails } from "../../../../domain/usages/update-program-details";

interface UpdateProgramSLiceType {
  onUpdate:Function;
  handleEdit: Function;
  value: number;
}
const initialStates = {
  value: 0,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdateProgramSlice: StateCreator<UpdateProgramSLiceType> = (
  set,
  get
) => ({
  ...initialStates,
  onUpdate: async (payload:UpdateProgramDetails.Params) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteUpdateProgramDetails: UpdateProgramDetails =
      new RemoteUpdateProgramDetails(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.UPDATE_PROGRAM_DETAILS}`,
        axiosHttpClient
      );

    let result = await remoteUpdateProgramDetails.update(
      useEnrollmentMainStore.getState().selectedCard.id.toString(),
      payload
    );
    if (result.success) {
      useEnrollmentMainStore.getState().reloadPage();
      Swal.fire("Enrollment Updated successfully.", "", "success");
      get().handleEdit(0);
    } else {
      useEnrollmentMainStore.getState().reloadPage();
      Swal.fire("Enrollment Updation failed.", "", "error");
    }
  },
  handleEdit: (x: number) => {
    set(() => ({ value: x }));
  },
});
