import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  PRODUCT_SERVICE_TOKEN,
  PSP_AUTH_TOKEN_KEY,
  PRODUCT_SERVICE_API_URL,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteFetchProgramProducts } from "../../../../data/usecases/remote-fetch-program-products";
import { FetchProgramProducts } from "../../../../domain/usages/fetch-program-products";
import { ProgramProductOptionType } from "../../../../domain/models/program-product-option-type";

interface FetchProgramProductSliceType {
  programProductOptions: ProgramProductOptionType[];
  fetchProgramProductOptions: Function;
}

const initialStates = {
  programProductOptions: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchProgramProductSlice: StateCreator<
  FetchProgramProductSliceType
> = (set) => ({
  ...initialStates,
  fetchProgramProductOptions: async (groupId: string) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);

    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${PRODUCT_SERVICE_TOKEN}`,
    });
    const remoteFetchProgramProductts: FetchProgramProducts =
      new RemoteFetchProgramProducts(
        `${PRODUCT_SERVICE_API_URL}${Endpoints.FETCH_PROGRAM_PRODUCTS}`,
        axiosHttpClient
      );

    let result = await remoteFetchProgramProductts.fetch(groupId);
    if (result.success) {
      set(() => ({ programProductOptions: result.data.result }));
    }
  },
});
