
import { useCallback, useState } from "react";
import { GenerateSignedUrlForDocumentUpload } from "../../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../../domain/usages/confirm-document-upload";


export type FileUpload = {
  file: any;
  uuid?: string;
  url?: string;
  success: boolean;
};

const useFileUpload = (
  generateUrl: GenerateSignedUrlForDocumentUpload,
  upload: UploadFile,
  confirm: ConfirmDocumentUpload,
  documentGroup:string,
  documentType:string,
  enrolmentId: number | null
): [FileUpload | undefined, boolean, Function, Function] => {
  const [fileUpload, setFileUpload] = useState<FileUpload>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reset = () => {
    setFileUpload(undefined);
  };

  const uploadFile = async (file: File) => {
    setIsLoading(true);
    generateUploadUrl({ file: file, success: false })
      .then((res: any) => uploadToBucket(res))
      .then((res: any) => confirmUpload(res))
      .then((res: any) => setIsLoading(false))
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

 

  const generateUploadUrl = useCallback(
    async (fileUpload: FileUpload) => {
      let payload: any = {
        name: fileUpload.file.name,
        size: fileUpload.file.size,
        type: fileUpload.file.type,
      };
      if (documentGroup) payload["document_group"] = documentGroup;
      if (documentType) payload["document_type"] = documentType;
      if (enrolmentId) payload["enrolment_id"] = enrolmentId;


      let result = await generateUrl.generate(payload);
      if (result["status"]) {
        fileUpload["uuid"] = result["data"]["uuid"];
        fileUpload["url"] = result["data"]["url"];
        fileUpload.success = true;
        return fileUpload;
      } else {
        throw new Error("Couldn't generate url.");
      }
    },
    [generateUrl]
  );

  const uploadToBucket = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload["url"]) {
        let params={
          file: fileUpload["file"],
          url: fileUpload["url"]
        }
        let result = await upload.upload(params);
        if (result) {
          fileUpload["success"] = true;
          return fileUpload;
        } else {
          throw new Error("Couldn't upload to bucket.");
        }
      } else {
        throw new Error("Couldn't upload to bucket.");
      }
    },
    [upload]
  );

  const confirmUpload = useCallback(
    async (fileUpload: FileUpload) => {
      if (fileUpload["uuid"]) {
        let params={
          uuid: fileUpload["uuid"]
        }
        let result = await confirm.upload(params);
        if (result["status"]) {
          fileUpload["success"] = true;
          setFileUpload(fileUpload);
          return fileUpload;
        } else {
          throw new Error("Couldn't confirm the upload.");
        }
      } else {
        throw new Error("Couldn't confirm the upload.");
      }
    },
    [confirm]
  );


  return [fileUpload, isLoading,  uploadFile, reset];
};

export default useFileUpload;
