import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { RemoteDeliverOrder } from "../../../data/usecases/remote-deliver-order";
import Endpoints from "../../../domain/endpoints";
import { DeliverOrderResponse } from "../../../domain/models/deliver-order";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { useOrderDetailsStore } from "./orderDetailsStore";
import moment from "moment";
import { DeliverOrder } from "../../../domain/usages/deliver-order";

interface DeliverOrderSliceType {
  handleDeliverOrder: (data: DeliverOrderResponse) => void;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useDeliverOrderSlice: StateCreator<
  DeliverOrderSliceType
> = () => ({
  handleDeliverOrder: async (data: DeliverOrderResponse) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteDeliverOrder: DeliverOrder = new RemoteDeliverOrder(
      `${PSP_ORDER_SERVICE}${Endpoints.DELIVER_ORDER}`,
      axiosHttpClient
    );

    let payload = {
      delivery_date:
        data.delivery_date &&
        moment(data.delivery_date.toString()).format("YYYY-MM-DD"),
      received_by: data.received_by,
      received_by_mobile: data.received_by_mobile,
    };
    let result = await remoteDeliverOrder.put(
      useOrderDetailsStore.getState().selectedOrder?.id,
      payload
    );
    if (result?.success) {
      useOrderDetailsStore.getState().reloadPage();
      Swal.fire({ title: result?.message, icon: "success", timer: 2000 });
    } else {
      Swal.fire({
        title: result?.errors?.message,
        icon: "error",
        timer: 2000,
      });
    }
  },
});
