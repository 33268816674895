import { Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Constant } from "../../../../../constants/constant";
import { Caretaker } from "../../../../../domain/models/personal-details-type";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Table, TableCell, TableContainer, TableRow } from "@mui/material";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import EditableTypes from "../../../../../domain/enum";

type BasicPersonalDetailType = {
  fullname: string;
  age: number | null;
  gender: string;
  comments: string;
  dateOfBirth?: Dayjs | null;
  noOfFamilyMembers: number;
  noOfEarningFamilyMembers: number;
  annualIncomeOfFamily: number;
  caretakers: {
    fullName: string;
    mobile: string;
    altMobile: string;
  };
};

const EditBasicPersonalDetails: React.FC = () => {
  const {
    selectedPatientPersonalDetails,
    editPersonalDetails,
    handleEditPersonalDetails,
    selectedToEdit,
  } = usePersonalDetailStore();
  const { handleSubmit, control, setValue, watch } =
    useForm<BasicPersonalDetailType>({
      mode: "onChange",
    });
  const [openDate, setOpenDate] = useState<boolean>(false);

  const GenderSelector = [
    {
      name: "Male",
      value: "male",
    },
    {
      name: "Female",
      value: "female",
    },
    {
      name: "Others",
      value: "others",
    },
  ];

  const [caretakerDetails, setCaretakerDetails] = useState<Caretaker[]>([]);

  useEffect(() => {
    if (selectedToEdit === EditableTypes.BASIC) {
      setValue("fullname", selectedPatientPersonalDetails?.fullName);
      setValue(
        "dateOfBirth",
        selectedPatientPersonalDetails.dateOfBirth
          ? dayjs(selectedPatientPersonalDetails.dateOfBirth)
          : null
      );
      setValue("gender", selectedPatientPersonalDetails?.gender);
      setValue(
        "annualIncomeOfFamily",
        selectedPatientPersonalDetails.annualIncomeOfFamily
      );
      setValue(
        "noOfEarningFamilyMembers",
        selectedPatientPersonalDetails.noOfEarningFamilyMembers
      );
      setValue(
        "noOfFamilyMembers",
        selectedPatientPersonalDetails.noOfFamilyMembers
      );
      setValue("comments", "");
      setValue(
        "age",
        selectedPatientPersonalDetails.age
          ? parseInt(selectedPatientPersonalDetails.age)
          : null
      );
    }
  }, [selectedPatientPersonalDetails._id]);

  const handleClick = (data: BasicPersonalDetailType) => {
    let payload = {
      fullName: data.fullname,
      gender: data.gender,
      dateOfBirth: data.dateOfBirth
        ? moment((data.dateOfBirth as Dayjs).toString()).format(
            Constant.YEAR_MONTH_DATE_FORMAT
          )
        : null,
      age: data.age && data.age.toString(),
      comments: data.comments,
      annualIncomeOfFamily: data.annualIncomeOfFamily,
      noOfEarningFamilyMembers: data.noOfEarningFamilyMembers,
      noOfFamilyMembers: data.noOfFamilyMembers,
    };
    editPersonalDetails(
      selectedPatientPersonalDetails._id.toString(),
      payload,
      false
    );
  };

  useEffect(() => {
    if (watch("dateOfBirth")) {
      calculateAge();
    }
  }, [watch("dateOfBirth")]);

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(
      moment((watch("dateOfBirth") as Dayjs).toString()).format("YYYY-MM-DD")
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValue("age", age);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle2" display="block" fontWeight={660}>
            Basic Details
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit(handleClick)}
            >
              <Typography variant="caption" textTransform={"none"}>
                {" "}
                Update
              </Typography>
            </Button>
            <Button
              size="small"
              variant="text"
              color="secondary"
              onClick={() => handleEditPersonalDetails(null)}
            >
              <Typography variant="caption" textTransform={"none"}>
                Close
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableRow sx={{ border: 0 }}>
              <TableCell sx={{ border: 0 }}>Full Name:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="fullname"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      placeholder="Enter Full Name"
                      variant="outlined"
                      size="small"
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Gender:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="gender"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="gender"
                      fullWidth
                      select
                      placeholder="Enter Gender"
                      size="small"
                      variant="outlined"
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    >
                      {GenderSelector.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Date of Birth:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={openDate}
                          onClose={() => {
                            setOpenDate(false);
                          }}
                          onOpen={() => {
                            setOpenDate(true);
                          }}
                          disableFuture
                          value={value}
                          onChange={onChange}
                          format="DD/MM/YYYY"
                        />
                      </LocalizationProvider>
                    </>
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Age:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="age"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={(event: any) => {
                        onChange(event.target.value);

                        setValue("dateOfBirth", null);
                      }}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                    pattern: {
                      value: Constant.NUMBER_ONLY.VALUE,
                      message: Constant.NUMBER_ONLY.MESSAGE,
                    },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>No of Family Members:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="noOfFamilyMembers"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                    pattern: {
                      value: Constant.NUMBER_ONLY.VALUE,
                      message: Constant.NUMBER_ONLY.MESSAGE,
                    },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Earning Family Members:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="noOfEarningFamilyMembers"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                    pattern: {
                      value: Constant.NUMBER_ONLY.VALUE,
                      message: Constant.NUMBER_ONLY.MESSAGE,
                    },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Annual Income Of Family:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="annualIncomeOfFamily"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                    pattern: {
                      value: Constant.NUMBER_ONLY.VALUE,
                      message: Constant.NUMBER_ONLY.MESSAGE,
                    },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ border: 0 }}>Comments:</TableCell>
              <TableCell sx={{ border: 0 }}>
                <Controller
                  name="comments"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={value}
                      error={error && true}
                      helperText={error?.message}
                      onChange={onChange}
                      sx={{
                        borderRadius: "3.5px",
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: Constant.FIELD_REQUIRED },
                  }}
                />
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
export default EditBasicPersonalDetails;
