import { createElement } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import indexRoutes, { pageRoutes } from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={pageRoutes.loginPage} />} />
        {indexRoutes.map((prop, key) => {
          return (
            <Route
              key={key}
              path={prop.path}
              element={createElement(prop.component)}
            >
              {prop.children?.map((prop, key) => {
                return (
                  <Route
                    key={key}
                    path={prop.path}
                    element={createElement(prop.component)}
                  ></Route>
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
