import { StateCreator } from "zustand";
import { RemoteUploadFile } from "../../../../data/usecases/remote-upload-file";
import {
  BucketImageType,
  UploadFileType,
} from "../../../../domain/models/upload-file-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useDocumentStore } from "./documentStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { AUTH_HEADER, PSP_AUTH_TOKEN_KEY } from "../../../../base";
import { UploadFile } from "../../../../domain/usages/upload-file";

interface UploadDocumentSliceType {
  uploadToBucket: Function;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUploadDocumnetSlice: StateCreator<
  UploadDocumentSliceType
> = () => ({
  uploadToBucket: async (
    fileUpload: UploadFileType,
    generatedUrlData: BucketImageType
  ) => {
    let token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

    const remoteUploadFile: UploadFile = new RemoteUploadFile(axiosHttpClient);

    if (generatedUrlData.url) {
      let result = await remoteUploadFile.upload({
        file: fileUpload,
        url: generatedUrlData.url,
      });
      if (result) {
        useDocumentStore.getState().confirmUpload(generatedUrlData.uuid);
      } else {
        throw new Error("Couldn't upload to bucket.");
      }
    }
  },
});
