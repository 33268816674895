import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack, TextField } from "@mui/material";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PatientAddressType } from "../../../../../domain/models/personal-details-type";
import DeleteIcon from "@mui/icons-material/Delete";
import DeactivateAddressConfirmModal from "./DeactivateAddressConfirmModal";

type Props = {
  successCallBack: Function;
};

const AddressDetails: React.FC<Props> = ({ successCallBack }) => {
  const { selectedPatientPersonalDetails, updatePatientAddresses } =
    usePersonalDetailStore();
  const filterPrimaryAddress = selectedPatientPersonalDetails.addresses.filter(
    (mobile) => {
      return mobile.type === "primary" && mobile.status === "active";
    }
  );

  const filterSecondaryAddress =
    selectedPatientPersonalDetails.addresses.filter((mobile) => {
      return mobile.type === "secondary" && mobile.status === "active";
    });

  const [deactivateModalDetails, setDeactivateModalDetails] =
    useState<PatientAddressType>({} as PatientAddressType);

  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);

  const keysToExclude = ["status", "type", "_id"];

  const handleOpenDeactivateModal = (address: PatientAddressType) => {
    setDeactivateModalDetails(address);
    setOpenDeactivateModal(true);
  };
  const handleCloseDeactivateModal = () => {
    setDeactivateModalDetails({} as PatientAddressType);
    setOpenDeactivateModal(false);
  };

  const handleAddressFormat = (data: PatientAddressType) => {
    const filteredAddress = Object.values(
      data
        ? Object.entries(data).reduce((acc: any, [key, value]) => {
            if (
              !keysToExclude.includes(key) &&
              value !== "" &&
              value !== null
            ) {
              acc[key] = value;
            }
            return acc;
          }, {})
        : {}
    );
    return filteredAddress;
  };

  const handleDetactivateAddress = (data: PatientAddressType) => {
    let payload = {
      status: "in_active",
    };
    updatePatientAddresses(
      selectedPatientPersonalDetails._id,
      data._id,
      payload,
      handleCloseDeactivateModal
    );
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography variant="subtitle2" display="block" fontWeight={660}>
              Address Details
            </Typography>
            <AddCircleIcon
              color="secondary"
              sx={{ fontSize: "15px" }}
              onClick={() => successCallBack("", true)}
            />
          </Stack>

          {filterPrimaryAddress.length > 0 && (
            <>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="caption" display="block" fontWeight={660}>
                  Primary Address
                </Typography>
              </Stack>
              {filterPrimaryAddress.length > 0 &&
                filterPrimaryAddress.map((primaryAddress, index) => {
                  if (primaryAddress.status == "active") {
                    return (
                      <Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography variant="caption" fontWeight={660}>
                            Address: {index + 1}
                          </Typography>
                          <DeleteIcon
                            color="error"
                            sx={{ fontSize: "15px" }}
                            onClick={() =>
                              handleOpenDeactivateModal(primaryAddress)
                            }
                          />
                        </Stack>
                        <TextField
                          variant="outlined"
                          fullWidth
                          inputProps={{ readOnly: true }}
                          size="small"
                          value={Object.values(
                            handleAddressFormat(primaryAddress)
                          ).join(",")}
                          multiline
                          rows={4}
                          sx={{
                            border: 0,
                          }}
                        />
                      </Stack>
                    );
                  }
                })}
            </>
          )}
          {filterSecondaryAddress.length > 0 && (
            <>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                marginTop={2}
              >
                <Typography variant="caption" fontWeight={660}>
                  Secondary Address
                </Typography>
              </Stack>
              {filterSecondaryAddress.length > 0 &&
                filterSecondaryAddress.map((secondaryAddress, index) => {
                  if (secondaryAddress.status == "active") {
                    return (
                      <Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <Typography variant="caption" fontWeight={660}>
                            Address: {index + 1}
                          </Typography>
                          <DeleteIcon
                            color="error"
                            sx={{ fontSize: "15px" }}
                            onClick={() =>
                              handleOpenDeactivateModal(secondaryAddress)
                            }
                          />
                        </Stack>
                        <TextField
                          variant="outlined"
                          fullWidth
                          inputProps={{ readOnly: true }}
                          size="small"
                          value={Object.values(
                            handleAddressFormat(secondaryAddress)
                          ).join(",")}
                          multiline
                          rows={4}
                          sx={{
                            border: 0,
                          }}
                        />
                      </Stack>
                    );
                  }
                })}
            </>
          )}
        </CardContent>
      </Card>
      <DeactivateAddressConfirmModal
        open={openDeactivateModal}
        handleClose={handleCloseDeactivateModal}
        data={deactivateModalDetails}
        successCallback={handleDetactivateAddress}
      />
    </>
  );
};

export default AddressDetails;
