import { AcceptPatientConsent } from "../../domain/usages/accept-patient-consent";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteAcceptPatientConsent implements AcceptPatientConsent {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async accept(
    token: string,
    params: AcceptPatientConsent.Params
  ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":token", token),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
  
      return httpResponse.data;
    
  }
}
