import { create } from "zustand";
import { useCreateOrderSlice } from "./createOrderSlice";
import { useFetchProductSlice } from "./fetchProductSlice";
import { useFetchSupplierSlice } from "./fetchSupplierListSLice";
import { useFetchProgramProductSlice } from "./fetchProgramProductSlice";

interface SendToCFAStoreInterface
  extends ReturnType<typeof useCreateOrderSlice>,
    ReturnType<typeof useFetchProductSlice>,
    ReturnType<typeof useFetchSupplierSlice>,
    ReturnType<typeof useFetchProgramProductSlice> {}

export const useSendToCFAStore = create<SendToCFAStoreInterface>((...data) => ({
  ...useCreateOrderSlice(...data),
  ...useFetchSupplierSlice(...data),
  ...useFetchProductSlice(...data),
  ...useFetchProgramProductSlice(...data),
}));
