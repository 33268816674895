import React, { useEffect, useRef, useState } from "react";
import { GenerateSignedUrlForDocumentUpload } from "../../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../../domain/usages/confirm-document-upload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PatientAddress } from "../../../domain/models/patient-address";
import { Controller, useForm } from "react-hook-form";
import { ProgramInitiator } from "../../../constants/enums/programInitiator";
import { Constant } from "../../../constants/constant";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import moment from "moment";
import { usePersonalDetailStore } from "../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import useFileUpload from "../hooks/useFileUpload";
import { LoadingButton } from "@mui/lab";
import InformationText from "../InformationText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadFileCard from "../UploadFileCard";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { ConsentTokenEnrolmentData } from "../../../domain/models/consent-token-enrolment-data";
import { useConsentStore } from "../../../store/patientConsentStore/consentStore";
import FormLoading from "../Loading/FormLoading";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../base";
import useRecaptcha from "../../hooks/reCaptcha";
import CaptureImageModal from "../modals/CaptureImageModal";

type Props = {
  generateUploadURL: GenerateSignedUrlForDocumentUpload;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmDocumentUpload;
  programId: string;
  successCallback: Function;
  title: string;
  isConsent?: boolean;
};

type EnrollmentFormFields = {
  initiator: string;
  patientCaretaker: string;
  hospitalCoordinator: string;
  patientName: string;
  patientStatus: string;
  mobile: string;
  email: string;
  dateOfBirth: Dayjs | null;
  age: number | null;
  address: PatientAddress;
  gender: string;
  doctorName: string;
  hospitalName: string;
  medicineName: string | number;
  prescriptionUuid: string | null;
  identityUuid: string | null;
  purchaseInvoiceUuid: string | null;
  addressProofUuid: string | null;
  consent: boolean;
  demoConfirmation: boolean;
  [key: string]: string | number | boolean | null | Dayjs | PatientAddress;
};

const AstellasEnrollmentForm: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  programId,
  successCallback,
  title,
  isConsent = false,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EnrollmentFormFields>({
    mode: "onChange",
  });
  const { token, executeRecaptcha } = useRecaptcha(
    GOOGLE_RECAPTCHE_SITE_KEY,
    "login"
  );
  const [openDate, setOpenDate] = useState<boolean>(false);
  const {
    handlePincode,
    district,
    state,
    country,
    city,
    editPersonalDetails,
    addAddress,
  } = usePersonalDetailStore();
  const { productOptions, fetchProductOptions, productOptionsLoading } =
    useSendToCFAStore();

  const { programOptions, fetchProgramOptions, programOptionsloading } =
    useProgramDetailStore();

  const { handleRefreshUrl } = useDocumentStore();
  const { createEnrolmentLoading } = useEnrollmentMainStore();
  const { consentTokenEnrolmentDetails, consentLoading } = useConsentStore();
  const [openSelectImageCaptureMode, setOpenSelectImageCaptureMode] =
    useState<boolean>(false);

  const [selectedUploadRef, setSelectedUploadRef] = useState<
    "prescription" | "id" | "address" | "purchaseInvoice" | ""
  >("");

  const prescriptionCameraInputRef = useRef<HTMLInputElement>(null);
  const prescriptionGalleryInputRef = useRef<HTMLInputElement>(null);
  const idCameraInputRef = useRef<HTMLInputElement>(null);
  const idGalleryInputRef = useRef<HTMLInputElement>(null);
  const addressCameraInputRef = useRef<HTMLInputElement>(null);
  const addressGalleryInputRef = useRef<HTMLInputElement>(null);
  const purchaseInvoiceCameraInputRef = useRef<HTMLInputElement>(null);
  const purchaseInvoiceGalleryInputRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState<EnrollmentFormFields>(
    {} as EnrollmentFormFields
  );

  const fieldRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLDivElement | null;
  }>({
    initiator: null,
    patientCaretaker: null,
    hospitalCoordinator: null,
    patientName: null,
    patientStatus: null,
    mobile: null,
    email: null,
    age: null,
    address: null,
    gender: null,
    medicineName: null,
    prescriptionUuid: null,
    identityUuid: null,
    purchaseInvoiceUuid: null,
    addressProofUuid: null,
    consent: null,
  });

  const handleScrollToError = () => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      fieldRefs.current[firstErrorField]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSelectedUploadRef = (
    value: "prescription" | "id" | "address" | "purchaseInvoice" | ""
  ) => {
    setSelectedUploadRef(value);
  };

  const onError = () => {
    handleScrollToError();
  };

  const handleCaptureFromCamera = () => {
    handleOpenSelectCaptureMode(false, "");
    if (
      selectedUploadRef == "prescription" &&
      prescriptionCameraInputRef.current
    ) {
      prescriptionCameraInputRef.current.click();
    } else if (selectedUploadRef == "id" && idCameraInputRef.current) {
      idCameraInputRef.current.click();
    } else if (
      selectedUploadRef == "address" &&
      addressCameraInputRef.current
    ) {
      addressCameraInputRef.current.click();
    } else if (
      selectedUploadRef == "purchaseInvoice" &&
      purchaseInvoiceCameraInputRef.current
    ) {
      purchaseInvoiceCameraInputRef.current.click();
    }
  };

  const handleCaptureFromGallery = () => {
    handleOpenSelectCaptureMode(false, "");
    if (
      selectedUploadRef == "prescription" &&
      prescriptionGalleryInputRef.current
    ) {
      prescriptionGalleryInputRef.current.click();
    } else if (selectedUploadRef == "id" && idGalleryInputRef.current) {
      idGalleryInputRef.current.click();
    } else if (
      selectedUploadRef == "address" &&
      addressGalleryInputRef.current
    ) {
      addressGalleryInputRef.current.click();
    } else if (
      selectedUploadRef == "purchaseInvoice" &&
      purchaseInvoiceGalleryInputRef.current
    ) {
      purchaseInvoiceGalleryInputRef.current.click();
    }
  };

  const handleOpenSelectCaptureMode = (
    value: boolean,
    refString: "prescription" | "id" | "address" | "purchaseInvoice" | ""
  ) => {
    handleSelectedUploadRef(refString);
    setOpenSelectImageCaptureMode(value);
  };

  const [
    prescriptionImageUpload,
    processingprescriptionImage,
    processPrescriptionImage,
    resetPrescriptionImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "Medical",
    "Prescription",
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null
  );

  const [
    identifyImageUpload,
    processingIdentifyImage,
    processIdentifyImage,
    resetIdentifyImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "Personal",
    "Identification",
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null
  );
  const [
    invoiceImageUpload,
    processingInvoiceImage,
    processInvoiceImage,
    resetInvoiceImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "Invoice",
    "Invoice",
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null
  );
  const [
    addressImageUpload,
    processingAddressImage,
    processAddressImage,
    resetAddressImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "Address",
    "AddressProof",
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null
  );
  const getImageUuidByType = (
    enrolment: ConsentTokenEnrolmentData,
    type: string
  ) => {
    if (enrolment && enrolment.documents.length > 0) {
      let document = enrolment.documents.find(
        (document) => document.document_type === type
      );
      if (document) {
        return document.uuid;
      } else {
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    if (consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id) {
      setValue("initiator", consentTokenEnrolmentDetails.initiator);
      setValue(
        "patientCaretaker",
        consentTokenEnrolmentDetails.patient_caretaker_name
      );
      setValue(
        "hospitalCoordinator",
        consentTokenEnrolmentDetails.hospital_coordinator_name
      );
      setValue("patientName", consentTokenEnrolmentDetails.patient_full_name);
      setValue("mobile", consentTokenEnrolmentDetails.mobile);
      setValue("email", consentTokenEnrolmentDetails.email);
      setValue("gender", consentTokenEnrolmentDetails.gender);
      setValue(
        "address.line",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.line
          : ""
      );
      setValue(
        "address.landmark",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.landmark
          : ""
      );
      setValue(
        "address.pincode",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.pin_code
          : ""
      );
      setValue(
        "demoConfirmation",
        consentTokenEnrolmentDetails.demo_confirmation ? true : false
      );

      setValue(
        "medicineName",
        consentTokenEnrolmentDetails.product_id.toString()
      );
      setValue("doctorName", consentTokenEnrolmentDetails.doctor_full_name);
      setValue("hospitalName", consentTokenEnrolmentDetails.hospital);
      setValue("age", consentTokenEnrolmentDetails.age);
      setValue("dateOfBirth", null);

      setValue(
        "prescriptionUuid",
        getImageUuidByType(consentTokenEnrolmentDetails, "Prescription")
      );
      setValue(
        "identityUuid",
        getImageUuidByType(consentTokenEnrolmentDetails, "Identification")
      );
      setValue(
        "purchaseInvoiceUuid",
        getImageUuidByType(consentTokenEnrolmentDetails, "Invoice")
      );
      setValue(
        "addressProofUuid",
        getImageUuidByType(consentTokenEnrolmentDetails, "AddressProof")
      );
      handleRefreshUrl();
      setValue(
        "consent",
        consentTokenEnrolmentDetails.consent_acknowledgement == 1 ? true : false
      );
      if (
        consentTokenEnrolmentDetails.address &&
        consentTokenEnrolmentDetails.address.pin_code
      ) {
        handlePincode(consentTokenEnrolmentDetails.address.pin_code);
      }
    }
  }, [consentTokenEnrolmentDetails.id]);

  useEffect(() => {
    if (prescriptionImageUpload && prescriptionImageUpload.uuid) {
      setValue("prescriptionUuid", prescriptionImageUpload.uuid);
    }
  }, [prescriptionImageUpload]);

  useEffect(() => {
    if (identifyImageUpload && identifyImageUpload.uuid) {
      setValue("identityUuid", identifyImageUpload.uuid);
    }
  }, [identifyImageUpload]);
  useEffect(() => {
    if (invoiceImageUpload && invoiceImageUpload.uuid) {
      setValue("purchaseInvoiceUuid", invoiceImageUpload.uuid);
    }
  }, [invoiceImageUpload]);

  useEffect(() => {
    if (addressImageUpload && addressImageUpload.uuid) {
      setValue("addressProofUuid", addressImageUpload.uuid);
    }
  }, [addressImageUpload]);

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(
      moment((watch("dateOfBirth") as Dayjs).toString()).format("YYYY-MM-DD")
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValue("age", age);
  };

  useEffect(() => {
    if (watch("dateOfBirth")) {
      calculateAge();
    }
  }, [watch("dateOfBirth")]);

  useEffect(() => {
    if (!(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)) {
      let params = {
        program_id: programId,
      };
      fetchProgramOptions(params);

      setValue("dateOfBirth", null);
    } else {
      let params = {
        program_id: consentTokenEnrolmentDetails.program_id,
      };
      fetchProgramOptions(params);
    }
  }, []);

  useEffect(() => {
    if (
      programOptions.length > 0 &&
      !(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)
    ) {
      let params = {
        program_id: programId,
      };
      fetchProductOptions(params);
    } else {
      let params = {
        program_id: consentTokenEnrolmentDetails.program_id,
      };
      fetchProductOptions(params);
    }
  }, [programOptions]);

  useEffect(() => {
    if (token) {
      let payload = {
        initiator: formData.initiator,
        patient_caretaker_name: formData.patientCaretaker
          ? formData.patientCaretaker
          : null,
        hospital_coordinator_name: formData.hospitalCoordinator
          ? formData.hospitalCoordinator
          : null,
        patient_full_name: formData.patientName,
        patient_status: formData.patientStatus,
        email: formData.email,
        mobile: formData.mobile,
        gender: formData.gender,
        demo_confirmation: formData.demoConfirmation ? 1 : 0,
        program_id: programId,
        product_id: formData.medicineName,
        prescription_uuid: formData.prescriptionUuid,
        address_proof_uuid: formData.addressProofUuid,
        identity_uuid: formData.identityUuid,
        date_of_birth: formData.dateOfBirth
          ? moment((formData.dateOfBirth as Dayjs).toString()).format(
              Constant.YEAR_MONTH_DATE_FORMAT
            )
          : null,
        age: formData.age,
        address: {
          pin_code: formData.address.pincode,
          district: district,
          state: state,
          line: formData.address.line,
          landmark: formData.address.landmark,
          country: country,
          city: city,
        },
        doctor_full_name: formData.doctorName ? formData.doctorName : null,
        hospital_name: formData.hospitalName ? formData.hospitalName : null,
        consent_accepted: formData.consent ? true : false,
        captcha: token,
      };
      if (payload && token) {
        if (consentTokenEnrolmentDetails.id && isConsent) {
          let updatePersonalDetailsPayload = {
            fullName: formData.patientName,
            gender: formData.gender,
            dateOfBirth: formData.dateOfBirth
              ? moment((formData.dateOfBirth as Dayjs).toString()).format(
                  Constant.YEAR_MONTH_DATE_FORMAT
                )
              : null,
            age: formData.age && formData.age.toString(),
          };
          let updateAddressPayload = {
            addresses: [
              {
                type: "primary",
                pincode: formData.address.pincode,
                district: district,
                state: state,
                line: formData.address.line,
                area: "",
                landmark: formData.address.landmark,
                country: country,
                city: city,
              },
            ],
          };
          addAddress(
            consentTokenEnrolmentDetails.patient_uuid,
            updateAddressPayload,
            () => {},
            true
          );
          editPersonalDetails(
            consentTokenEnrolmentDetails.patient_uuid,
            updatePersonalDetailsPayload,
            true
          );
          successCallback(payload);
        } else {
          successCallback(payload);
        }
      }
    }
  }, [token]);

  const onSubmit = (data: EnrollmentFormFields) => {
    setFormData(data);
    executeRecaptcha();
  };

  return (
    <Stack marginTop={1}>
      {programOptionsloading ||
      productOptionsLoading ||
      programOptions.length == 0 ? (
        <FormLoading />
      ) : (
        <>
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={660}
            color={"warning.dark"}
            sx={{ textDecoration: "underline" }}
          >
            {`${
              programOptions.length > 0 ? programOptions[0].name : ""
            } ${title}`}
          </Typography>
          <Stack margin={2} gap={2}>
            <Typography variant="body1" fontWeight={660}>
              Personal Details
            </Typography>
            {consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id ? (
              <Controller
                name="initiator"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    disabled
                    label="Initiator *"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    size="small"
                    helperText={error?.message}
                  />
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            ) : (
              <Controller
                name="initiator"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div ref={(el) => (fieldRefs.current["initiator"] = el)}>
                    <TextField
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      select
                      label="Initiator *"
                      value={value}
                      fullWidth
                      onChange={onChange}
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    >
                      <MenuItem value={ProgramInitiator.PATIENT}>
                        Patient Himself
                      </MenuItem>
                      <MenuItem value={ProgramInitiator.CARETAKER}>
                        Patient Caretaker
                      </MenuItem>
                      <MenuItem value={ProgramInitiator.HOSPITAL_COORDINATOR}>
                        Hospital Co-ordinator (on behalf of patient)
                      </MenuItem>
                    </TextField>
                  </div>
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            )}

            {watch("initiator") == ProgramInitiator.CARETAKER && (
              <Controller
                name="patientCaretaker"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={(el) =>
                      (fieldRefs.current["patientCaretaker"] = el)
                    }
                    label="Name of the Caretaker *"
                    disabled={
                      consentTokenEnrolmentDetails.consent_acknowledgement == 1
                        ? true
                        : false
                    }
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    size="small"
                    helperText={error?.message}
                  />
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            )}
            {watch("initiator") == ProgramInitiator.HOSPITAL_COORDINATOR && (
              <Controller
                name="hospitalCoordinator"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Name of Hospital Co-ordinator *"
                    inputRef={(el) =>
                      (fieldRefs.current["hospitalCoordinator"] = el)
                    }
                    disabled={
                      consentTokenEnrolmentDetails.consent_acknowledgement == 1
                        ? true
                        : false
                    }
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    size="small"
                    helperText={error?.message}
                  />
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            )}
            <Controller
              name="patientName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Patient Name *"
                  inputRef={(el) => (fieldRefs.current["patientName"] = el)}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1 ||
                    consentTokenEnrolmentDetails.patient_full_name
                      ? true
                      : false
                  }
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  size="small"
                  helperText={error?.message}
                />
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />
            <Controller
              name="patientStatus"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div ref={(el) => (fieldRefs.current["patientStatus"] = el)}>
                  <FormControl error={!!error}>
                    <FormLabel>Patient Status *</FormLabel>
                    <RadioGroup
                      name="patient_status"
                      value={value}
                      onChange={onChange}
                    >
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value="in_patient"
                          control={
                            <Radio
                              size="small"
                              checked={value == "in_patient"}
                              disabled={
                                consentTokenEnrolmentDetails.consent_acknowledgement ==
                                1
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="In Patient"
                        />
                        <FormControlLabel
                          value="out_patient"
                          control={
                            <Radio
                              size="small"
                              checked={value == "out_patient"}
                              disabled={
                                consentTokenEnrolmentDetails.consent_acknowledgement ==
                                1
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Out Patient"
                        />
                      </div>
                    </RadioGroup>

                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                </div>
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />
            <Controller
              name="mobile"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Mobile Number *"
                  inputRef={(el) => (fieldRefs.current["mobile"] = el)}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1 ||
                    consentTokenEnrolmentDetails.mobile
                      ? true
                      : false
                  }
                  value={value}
                  onChange={onChange}
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constant.CORRECT_MOBILE.VALUE,
                  message: "Invalid mobile",
                },
                required: { value: true, message: "Field Required" },
              }}
            />
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Email *"
                  inputRef={(el) => (fieldRefs.current["email"] = el)}
                  value={value}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  onChange={onChange}
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
              rules={{
                pattern: {
                  value: Constant.CORRECT_EMAIL.VALUE,
                  message: "Invalid email",
                },
                required: { value: true, message: "Field Required" },
              }}
            />
            <Stack
              flexDirection={"row"}
              gap={2}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Controller
                name="dateOfBirth"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={"Date of Birth "}
                        open={openDate}
                        onClose={() => {
                          setOpenDate(false);
                        }}
                        onOpen={() => {
                          setOpenDate(true);
                        }}
                        disableFuture
                        value={value}
                        onChange={onChange}
                        format="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </>
                )}
              />
              <Controller
                name="age"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={(el) => (fieldRefs.current["age"] = el)}
                    InputLabelProps={{ shrink: true }}
                    label="Age *"
                    disabled={
                      consentTokenEnrolmentDetails.consent_acknowledgement == 1
                        ? true
                        : false
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    onChange={(event: any) => {
                      onChange(event.target.value);
                      setValue("dateOfBirth", null);
                    }}
                    sx={{
                      borderRadius: "3.5px",
                    }}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                  pattern: {
                    value: Constant.NUMBER_ONLY.VALUE,
                    message: Constant.NUMBER_ONLY.MESSAGE,
                  },
                }}
              />
            </Stack>
            <Controller
              name="gender"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div ref={(el) => (fieldRefs.current["gender"] = el)}>
                  <FormControl error={!!error}>
                    <FormLabel>Gender *</FormLabel>
                    <RadioGroup name="gender" value={value} onChange={onChange}>
                      <div style={{ display: "flex" }}>
                        <FormControlLabel
                          value="male"
                          control={
                            <Radio
                              size="small"
                              checked={value == "male"}
                              disabled={
                                consentTokenEnrolmentDetails.consent_acknowledgement ==
                                1
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={
                            <Radio
                              size="small"
                              checked={value == "female"}
                              disabled={
                                consentTokenEnrolmentDetails.consent_acknowledgement ==
                                1
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Female"
                        />
                        <FormControlLabel
                          value="others"
                          control={
                            <Radio
                              size="small"
                              checked={value == "others"}
                              disabled={
                                consentTokenEnrolmentDetails.consent_acknowledgement ==
                                1
                                  ? true
                                  : false
                              }
                            />
                          }
                          label="Others"
                        />
                      </div>
                    </RadioGroup>

                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                </div>
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />
            <Controller
              name="address.line"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Address line *"
                  value={value}
                  inputRef={(el) => (fieldRefs.current["address"] = el)}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  onChange={onChange}
                  size="small"
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
            <Controller
              name="address.landmark"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Landmark *"
                  size="small"
                  inputRef={(el) => (fieldRefs.current["address"] = el)}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  value={value}
                  onChange={onChange}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
            <Controller
              name="address.pincode"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Pincode *"
                  size="small"
                  inputRef={(el) => (fieldRefs.current["address"] = el)}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);

                    handlePincode(e.target.value);
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
              rules={{
                required: { value: true, message: "Field Required" },
                pattern: {
                  value: Constant.CORRECT_PINCODE.VALUE,
                  message: Constant.CORRECT_PINCODE.MESSAGE,
                },
              }}
            />
            <Controller
              name="address.city"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="City *"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={city}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="address.district"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="District *"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={district}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="address.state"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  label="State *"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={state}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="address.country"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  label="Country *"
                  InputLabelProps={{ shrink: true }}
                  value={country}
                  InputProps={{
                    readOnly: true,
                  }}
                  error={error && true}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              name="doctorName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Doctor Name"
                  value={value}
                  onChange={onChange}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  error={!!error}
                  size="small"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="hospitalName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Hospital Name"
                  value={value}
                  disabled={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  onChange={onChange}
                  error={!!error}
                  size="small"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="medicineName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div ref={(el) => (fieldRefs.current["medicineName"] = el)}>
                  <TextField
                    select
                    fullWidth
                    label="Medicine Name *"
                    value={value}
                    disabled={
                      consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1 || consentTokenEnrolmentDetails.product_id
                        ? true
                        : false
                    }
                    size="small"
                    onChange={onChange}
                    error={error && true}
                    helperText={error?.message}
                  >
                    {productOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              rules={{
                required: { value: true, message: "Field Required" },
              }}
            />
            <Typography variant="body1" fontWeight={660}>
              Documents
            </Typography>

            <Controller
              name="prescriptionUuid"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div
                  ref={(el) => (fieldRefs.current["prescriptionUuid"] = el)}
                  style={{ width: "100%" }}
                >
                  <Stack>
                    <UploadFileCard
                      handleOpenModal={handleOpenSelectCaptureMode}
                      cameraInputRef={prescriptionCameraInputRef}
                      galleryInputRef={prescriptionGalleryInputRef}
                      uuid={value}
                      refName="prescription"
                      title="Upload Prescription *"
                      id={1}
                      isConsent={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onFileSelect={(file: File) =>
                        processPrescriptionImage(file)
                      }
                      file={prescriptionImageUpload?.file}
                      reset={() => resetPrescriptionImage()}
                      isLoading={processingprescriptionImage}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </Stack>
                </div>
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />

            <Controller
              name="identityUuid"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div
                  ref={(el) => (fieldRefs.current["identityUuid"] = el)}
                  style={{ width: "100%" }}
                >
                  <Stack>
                    <UploadFileCard
                      refName="id"
                      handleOpenModal={handleOpenSelectCaptureMode}
                      cameraInputRef={idCameraInputRef}
                      galleryInputRef={idGalleryInputRef}
                      uuid={value}
                      title="Upload Id proof Aadhar card/PanCard/Driving License etc *."
                      id={2}
                      isConsent={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onFileSelect={(file: File) => processIdentifyImage(file)}
                      file={identifyImageUpload?.file}
                      reset={() => resetIdentifyImage()}
                      isLoading={processingIdentifyImage}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </Stack>
                </div>
              )}
              rules={{ required: { value: true, message: "Field Required" } }}
            />

            <Controller
              name="purchaseInvoiceUuid"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div
                  ref={(el) => (fieldRefs.current["purchaseInvoiceUuid"] = el)}
                  style={{ width: "100%" }}
                >
                  <Stack>
                    <UploadFileCard
                      refName="purchaseInvoice"
                      handleOpenModal={handleOpenSelectCaptureMode}
                      cameraInputRef={purchaseInvoiceCameraInputRef}
                      galleryInputRef={purchaseInvoiceGalleryInputRef}
                      uuid={value}
                      title={`Upload Purchase Invoice ${
                        watch("patientStatus") == "out_patient" ? "*" : ""
                      }`}
                      id={3}
                      isConsent={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onFileSelect={(file: File) => processInvoiceImage(file)}
                      file={invoiceImageUpload?.file}
                      reset={() => resetInvoiceImage()}
                      isLoading={processingInvoiceImage}
                      error={!!error}
                      helperText={error?.message}
                    />
                  </Stack>
                </div>
              )}
              rules={{
                required: {
                  value: watch("patientStatus") == "out_patient" ? true : false,
                  message: "Field Required",
                },
              }}
            />

            <Controller
              name="addressProofUuid"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <UploadFileCard
                  refName="address"
                  handleOpenModal={handleOpenSelectCaptureMode}
                  cameraInputRef={addressCameraInputRef}
                  galleryInputRef={addressGalleryInputRef}
                  uuid={value}
                  title="Upload Address proof"
                  id={4}
                  isConsent={
                    consentTokenEnrolmentDetails.consent_acknowledgement == 1
                      ? true
                      : false
                  }
                  onFileSelect={(file: File) => processAddressImage(file)}
                  file={addressImageUpload?.file}
                  reset={() => resetAddressImage()}
                  isLoading={processingAddressImage}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            {programOptions.length > 0 && (
              <>
                {programOptions[0].patient_consent && (
                  <Accordion expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Consent
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InformationText
                        text={programOptions[0].patient_consent}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
                {programOptions[0].privacy_notice && (
                  <Accordion expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle2" fontWeight={660}>
                        Privacy Notice
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InformationText
                        text={programOptions[0].privacy_notice}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )}

            {consentTokenEnrolmentDetails.consent_acknowledgement === 1 ? (
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={<Checkbox defaultChecked disabled />}
                  label="Accept Consent"
                />
                <Typography>Date: {moment().format("YYYY-MM-DD")}</Typography>
              </Stack>
            ) : (
              <Controller
                name="consent"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox onChange={onChange} required value={value} />
                      }
                      label="Accept Consent"
                    />
                    <Typography>
                      Date: {moment().format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            )}

            {!consentTokenEnrolmentDetails.consent_acknowledgement && (
              <LoadingButton
                loading={consentLoading || createEnrolmentLoading}
                disabled={programOptions.length == 0 || !watch("consent")}
                variant="contained"
                color="secondary"
                loadingPosition="center"
                onClick={handleSubmit(onSubmit, onError)}
              >
                Submit
              </LoadingButton>
            )}
          </Stack>
          {/* <a href="tel:18002670909" target="_blank">
            <Box
              position={"fixed"}
              bottom={80}
              paddingX={2.2}
              paddingY={1}
              right={0}
              border={"1px solid lightgrey"}
              bgcolor={"white"}
              zIndex={9999}
              boxShadow={5}
            >
              <Stack alignItems={"center"}>
                <PhoneEnabledIcon color="secondary" />
              </Stack>

              <Stack alignItems={"center"}>
                <Typography
                  fontSize={"10px"}
                  color={"black"}
                  textAlign={"center"}
                >
                  Call Us
                </Typography>
              </Stack>
            </Box>
          </a> */}
        </>
      )}
      {openSelectImageCaptureMode && (
        <CaptureImageModal
          openCaptureImageModal={openSelectImageCaptureMode}
          handleCaptureFromGallery={handleCaptureFromGallery}
          handleCaptureFromCamera={handleCaptureFromCamera}
          handleCaptureImageModal={() => {
            handleOpenSelectCaptureMode(false, "");
          }}
        />
      )}
    </Stack>
  );
};

export default AstellasEnrollmentForm;
