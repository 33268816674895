import Box from "@mui/material/Box";
import { Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import FollowUpsPanel from "./option-panels/FollowUpsPanel";
import AllFollowUpsPanel from "./option-panels/AllPanel";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useFollowUpsStore } from "../../../../store/adminPageStore/followUpStore/followUpsStore";
import { toTitleCase } from "../../../../domain/Utils";

const PatientFollowupTabs: React.FC = () => {
  const [value, setValue] = useState("1");
  const { selectedCard } = useEnrollmentMainStore();
  const { followUpThreadForEnrolmentId } = useFollowUpsStore();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClick = () => {
    followUpThreadForEnrolmentId(selectedCard.id);
  };

  return (
    <TabContext value={value}>
      <Box>
        <TabList onChange={handleChange} centered>
          <Tab
            onClick={handleClick}
            label={
              <Typography
                variant="subtitle2"
                fontWeight={550}
                textTransform={"none"}
              >
                {`${
                  selectedCard.patient_full_name
                    ? toTitleCase(selectedCard.patient_full_name)
                    : ""
                } Follow-ups`}
              </Typography>
            }
            value="1"
          />
          <Tab
            label={
              <Typography
                variant="subtitle2"
                fontWeight={550}
                textTransform={"none"}
              >
                All Patient Follow-ups
              </Typography>
            }
            value="2"
          />
        </TabList>
        <TabPanel value="1">
          <FollowUpsPanel />
        </TabPanel>
        <TabPanel value="2">
          <AllFollowUpsPanel />
        </TabPanel>
      </Box>
    </TabContext>
  );
};
export default PatientFollowupTabs;
