import { create } from "zustand";
import {
  useCreateEnrollmentSlice,
} from "./createEnrollmentSlice";
import {
  useFetchEnrollmentSlice,
} from "./fetchEnrollmentSlice";
import {
  useFilterEnrollmentSlice,
} from "./filterEnrollmentSlice";
import { useCreateEnrollmentByPatientSlice } from "./createEnrollmentByPatientSlice";
import { useSendConsentSMSSlice } from "./sendConsentSMSSlice";


interface EnrollmentStoreInterface
  extends ReturnType<typeof useFetchEnrollmentSlice>,
    ReturnType<typeof useCreateEnrollmentSlice>,
    ReturnType<typeof useFilterEnrollmentSlice>,
    ReturnType<typeof useCreateEnrollmentByPatientSlice>,
    ReturnType<typeof useSendConsentSMSSlice>{}
    

export const useEnrollmentMainStore = create<EnrollmentStoreInterface>()(
  (...enrollment) => ({
    ...useFetchEnrollmentSlice(...enrollment),
    ...useCreateEnrollmentSlice(...enrollment),
    ...useFilterEnrollmentSlice(...enrollment),
    ...useCreateEnrollmentByPatientSlice(...enrollment),
    ...useSendConsentSMSSlice(...enrollment)
  })
);
