import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import { RemoteCreateNewEnrollment } from "../../../data/usecases/remote-create-new-enrollment";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "./enrollmentMainStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CreateNewEnrollment } from "../../../domain/usages/create-new-enrollment";
import { SendConsentSMS } from "../../../domain/usages/send-consent-sms";
import { RemoteSendConsentSMS } from "../../../data/usecases/remote-send-consent-sms";

interface SendConsentSMSSliceType {
  sendConsentSMS: Function
 sendConsentSMSLoading: boolean
 handleSendConsentSMSLoading: Function
}

const initialStates = {
    sendConsentSMSLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useSendConsentSMSSlice: StateCreator<
SendConsentSMSSliceType
> = (set, get) => ({
  ...initialStates,
  sendConsentSMS: async (enrollmentId: string) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });
    const remoteSendConsentSMS: SendConsentSMS =
      new RemoteSendConsentSMS(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.SEND_CONSENT_SMS}`,
        axiosHttpClient
      );

      set(() => ({ sendConsentSMSLoading: true }));
    let result = await remoteSendConsentSMS.send(enrollmentId);
    if (result.success) {
        set(() => ({ sendConsentSMSLoading: false }));
      get().handleSendConsentSMSLoading(false);
      Swal.fire(result.message, "", "success");
    } else {
        set(() => ({ sendConsentSMSLoading: false }));
      Swal.fire("Consent SMS failed to send.", "", "error");
    }
  },
  handleSendConsentSMSLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        title: "Response Loading...",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  },
 
});
