import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteAddPatientAddresses } from "../../../../data/usecases/remote-add-patient-addresses";
import { AddPatientAddresses } from "../../../../domain/usages/add-patient-address";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";

interface AddPatientAddressDetailSliceType {
  addAddress: Function;
  handleLoading: Function;

}
const initialStates = {};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useAddPatientAddressDetailSlice: StateCreator<
  AddPatientAddressDetailSliceType
> = (set, get) => ({
  ...initialStates,
  handleLoading: (loading: boolean) => {
    if (loading) {
      Swal.fire({
        title: "Response Loading...",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  },
  addAddress: async (
    patientId: string,
    payload: AddPatientAddresses.Params,
    handleCloseModal: Function, 
    isConsent:boolean
  ) => {
    let token = storage.get(PATIENT_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `Bearer ${atob(token)}`,
    });

    const remoteAddPatientAddresses: AddPatientAddresses =
      new RemoteAddPatientAddresses(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.ADD_PATIENT_ADDRESS_DETAILS}`,
        axiosHttpClient
      );
    get().handleLoading(true);
    handleCloseModal();
    let result = await remoteAddPatientAddresses.add(patientId, payload);
    if (result.success) {
      get().handleLoading(false);
      if(!isConsent){
        usePersonalDetailStore
        .getState()
        .getPatient(
          useEnrollmentMainStore.getState().selectedCard.patient_uuid
        );
      }
     
      usePersonalDetailStore.getState().handleEditPersonalDetails(null);
      Swal.fire("Added address details successfully.", "", "success");
    } else {
      get().handleLoading(false);
      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
});
