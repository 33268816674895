import React from "react";
import LoginPage from "./presentation/pages/LoginPage";
import StockistPage from "./presentation/pages/StockistPage";
import SupportAdminPage from "./presentation/pages/SupportAdminPage";
import { UploadPatientConsentFileFactory } from "./main/UploadPatientConsentFileFactory";
import { ProgramEnrolmentFactory } from "./main/ProgramEnrolmentFactory";

export const pageRoutes = {
  loginPage: "/login",
  adminPage: "/adminPage",
  orderService: "/orderService",
  consentForm: "/patients/:id/enrolments/:token",
  enrolmentForm:"/enrolments"
};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.loginPage,
    name: "Login Page",
    component: LoginPage,
  } as AppRoute,
  {
    path: pageRoutes.adminPage,
    name: "Admin Page",
    component: SupportAdminPage,
  } as AppRoute,
  {
    path: pageRoutes.orderService,
    name: "Order Page",
    component: StockistPage,
  } as AppRoute,
  {
    path: pageRoutes.consentForm,
    name: "Consent Form Page",
    component: UploadPatientConsentFileFactory,
  } as AppRoute,
  {
    path: pageRoutes.enrolmentForm,
    name: "Program Enrolment Form Page",
    component: ProgramEnrolmentFactory,
  } as AppRoute,
];

export default indexRoutes;
